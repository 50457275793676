/* tslint:disable */
/* eslint-disable */
import { AnchorProvisionInfoModel } from "./anchor-provision-info-model";
import { MatchingSubfieldInfoModel } from "./matching-subfield-info-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionIndexDependencyModel")
export class ProvisionIndexDependencyModel {
  @JsonProperty("anchorProvisionInfo", AnchorProvisionInfoModel, true)
  anchorProvisionInfo?: AnchorProvisionInfoModel = undefined;

  @JsonProperty("matchingSubfieldInfo", MatchingSubfieldInfoModel, true)
  matchingSubfieldInfo?: MatchingSubfieldInfoModel = undefined;
}
