/* tslint:disable */
/* eslint-disable */
import { ErrorDataModel } from "./error-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiRespAuthorityModel")
export class OApiRespAuthorityModel {
  /**
   * the response data
   */

  @JsonProperty("data", Any, true)
  data?: Array<
    | "DOCUMENT_COMPARISON"
    | "PROVIDE_CALLBACK_URL_FOR_UPLOADING_RECORD"
    | "SPLIT_DOC"
    | "EXPORT_RECORD_FILE_TYPES"
    | "EXPORT_RECORD_VIEWS"
    | "CREATE_RECORD"
    | "READ_RECORD"
    | "UPDATE_RECORD"
    | "DELETE_RECORD"
    | "ADD_PROVISION_INFO"
    | "CREATE_PROVISION_FORM"
    | "READ_PROVISION_FORM"
    | "UPDATE_PROVISION_FORM"
    | "DELETE_PROVISION_FORM"
    | "CREATE_PROJECT"
    | "READ_PROJECT"
    | "UPDATE_PROJECT"
    | "DELETE_PROJECT"
    | "CREATE_DASHBOARD"
    | "READ_DASHBOARD"
    | "UPDATE_DASHBOARD"
    | "DELETE_DASHBOARD"
    | "CREATE_TASK"
    | "READ_TASK"
    | "UPDATE_TASK"
    | "DELETE_TASK"
    | "CREATE_QUEUE"
    | "READ_QUEUE"
    | "UPDATE_QUEUE"
    | "DELETE_QUEUE"
    | "CREATE_DOC_SET"
    | "READ_DOC_SET"
    | "UPDATE_DOC_SET"
    | "DELETE_DOC_SET"
  > = [];

  /**
   * the developer-facing errors for the request
   */

  @JsonProperty("devErrors", [ErrorDataModel], true)
  devErrors?: Array<ErrorDataModel> = [];

  /**
   * the user-facing errors for the request
   */

  @JsonProperty("errors", [ErrorDataModel], true)
  errors?: Array<ErrorDataModel> = [];

  /**
   * the response message
   */

  @JsonProperty("message", String, true)
  message?: string = "";
}
