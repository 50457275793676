import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PermissionsGuard } from "@@intelease/web/intelease/guards";
import { FormsModule } from "@angular/forms";
import { ItlsChooseProvisionFormModule } from "@@intelease/web/ui/src/lib/itls-choose-provision-form";
import { AppStateQueueModule } from "@@intelease/app-state/queue/src";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  AddQueueModalComponent,
  CreateButtonPipe,
} from "./add-new-queue/add-queue-modal.component";
import { DuplicateQueueModalComponent } from "./duplicate-queue/duplicate-queue-modal.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
    ItlsChooseProvisionFormModule,
    AppStateQueueModule,
    MatTooltipModule,
    MatButtonModule,
    MatDialogModule,
  ],
  providers: [PermissionsGuard],
  declarations: [
    CreateButtonPipe,
    AddQueueModalComponent,
    DuplicateQueueModalComponent,
  ],
})
export class QueueModalModule {}
