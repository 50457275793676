import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DRIVE_FEATURE_KEY, DriveState } from "./drive.reducer";
import { ReportLogicalOperatorEnum } from "@@intelease/web/intelease/constants";
import { SelectedFilterModel } from "../models/drive-node.types";

// Lookup the 'Drive' feature state managed by NgRx
const getRecordsState = createFeatureSelector<DriveState>(DRIVE_FEATURE_KEY);

const loadAllDriveNodesSucceeded = createSelector(
  getRecordsState,
  (state: DriveState) => state.loadAllDriveNodesSucceeded
);

const loadAllDriveNodesError = createSelector(
  getRecordsState,
  (state: DriveState) => state.loadAllDriveNodesError
);

const isAllDriveNodesLoading = createSelector(
  getRecordsState,
  (state: DriveState) => state.isAllDriveNodesLoading
);

const getAllRecordsList = createSelector(
  getRecordsState,
  loadAllDriveNodesSucceeded,
  (state: DriveState, isLoaded) => (isLoaded ? state.driveNodes : undefined)
);

const getNodeNavigation = createSelector(
  getRecordsState,
  loadAllDriveNodesSucceeded,
  (state: DriveState, isLoaded) =>
    isLoaded ? state.driveNodes?.nodeNavigation : undefined
);

const getAppliedSelectedColumnFields = createSelector(
  getRecordsState,
  (state: DriveState) => state.selectedColumnFields.applied
);

const getCurrentSelectedColumnFields = createSelector(
  getRecordsState,
  (state: DriveState) => state.selectedColumnFields.current
);

const getPagination = createSelector(
  getRecordsState,
  (state: DriveState) => state.driveNodes?.pagination
);

const getCurrentSelectedFilter = createSelector(
  getRecordsState,
  (state: DriveState) =>
    state.selectedFilter?.current || {
      operator: ReportLogicalOperatorEnum.AND,
      value: [],
    }
);

const getAppliedSelectedFilter = createSelector(
  getRecordsState,
  (state: DriveState) =>
    state.selectedFilter?.applied || {
      operator: ReportLogicalOperatorEnum.AND,
      value: [],
    }
);

const getNumberOfFilters = createSelector(
  getRecordsState,
  getAppliedSelectedFilter,
  (state: DriveState, selectedFilter: SelectedFilterModel) =>
    selectedFilter.value.length || 0
);

const isSortRecordFailed = createSelector(
  getRecordsState,
  (state: DriveState) =>
    state.sortDriveNodesFailed && !state.sortDriveNodesLoading
);

export const driveNodeQuery = {
  isAllDriveNodesLoading,
  loadAllDriveNodesSucceeded,
  loadAllDriveNodesError,
  getAllRecordsList,

  getAppliedSelectedColumnFields,
  getCurrentSelectedColumnFields,

  getPagination,
  getNumberOfFilters,
  getAppliedSelectedFilter,
  getCurrentSelectedFilter,

  isSortRecordFailed,

  getNodeNavigation,
};
