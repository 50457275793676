import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuardService } from "@@intelease/web/intelease/guards";
import { environment } from "../environments/environment";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";

const routes: Routes = [
  { path: "", redirectTo: "dashboards", pathMatch: "full" },
  {
    path: "user-profile",
    loadChildren: () =>
      import("@@intelease/web/user-profile").then(
        (m) => m.WebUserProfileModule
      ),
    canActivate: [AuthGuardService],
    data: {
      title: "user-profile",
      breadcrumbs: [
        {
          key: "USER_PROFILE",
        },
      ],
    },
  },
  {
    path: "settings",
    loadChildren: () =>
      import("@@intelease/web/settings").then((m) => m.WebSettingsModule),
    canActivate: [AuthGuardService],
    data: {
      title: "settings",
      breadcrumbs: [
        {
          key: "SETTINGS",
        },
      ],
    },
  },
  {
    path: "home",
    loadChildren: () =>
      import("@@intelease/web/ui/src/lib/itls-dynamic-dashboard").then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuardService],
    data: {},
  },
  {
    path: "dashboards",
    loadChildren: () =>
      import("@@intelease/web/ui/src/lib/itls-dynamic-dashboard").then(
        (m) => m.ItlsDynamicDashboardModule
      ),
    canActivate: [AuthGuardService],
    data: {},
  },
  {
    path: "drive",
    loadChildren: () =>
      import("@@intelease/web/ui/src/lib/itls-drive").then(
        (m) => m.ItlsDriveModule
      ),

    canActivate: [AuthGuardService],
  },
  {
    path: "individual-abstract/:uid",
    loadChildren: () =>
      import("@@intelease/web/abstract-detail").then(
        (m) => m.WebAbstractDetailModule
      ),
    canActivate: [AuthGuardService],
    data: {
      title: "individual-abstract",
    },
  },
  {
    path: "provision-forms/:status",
    loadChildren: () =>
      import("@@intelease/web/forms").then((m) => m.WebFormsModule),
    canActivate: [AuthGuardService],
    data: {
      title: "provision-forms",
      breadcrumbs: [
        {
          key: "FORMS",
        },
      ],
    },
  },
  {
    path: "individual-form/:uid",
    loadChildren: () =>
      import("@@intelease/web/form-builder").then(
        (m) => m.WebFormBuilderModule
      ),
    canActivate: [AuthGuardService],
    data: {
      title: "individual-form",
      breadcrumbs: [
        {
          key: "FORMS",
        },
        {
          key: "FORM_DETAIL",
        },
      ],
    },
  },
  {
    path: "documents/:docSetUid",
    loadChildren: () =>
      import("@intelease/web/doc-set-preview").then(
        (m) => m.WebDocSetPreviewModule
      ),
    canActivate: [AuthGuardService],
    data: {
      title: "documents",
    },
  },
  {
    path: "record-review/:uid",
    loadChildren: () =>
      import("@@intelease/web/abstract-review").then(
        (m) => m.WebAbstractReviewModule
      ),
    canActivate: [AuthGuardService],
    data: {
      title: "abstract-review",
    },
  },
  {
    path: "abstract-review/:uid",
    loadChildren: () =>
      import("@@intelease/web/abstraction-page").then(
        (m) => m.WebAbstractionPageModule
      ),
    canActivate: [AuthGuardService],
    data: {
      title: "abstract-review",
    },
  },
  {
    path: "tasks",
    loadChildren: () =>
      import("@@intelease/web/tasks").then((m) => m.WebTasksModule),
    canActivate: [AuthGuardService],
    data: {
      title: "tasks",
      breadcrumbs: [
        {
          key: "TASKS",
        },
      ],
    },
  },
  {
    path: "projects",
    loadChildren: () =>
      import("@@intelease/web/projects").then((m) => m.WebProjectsModule),
    canActivate: [AuthGuardService],
    data: {
      title: "Projects",
      breadcrumbs: [
        {
          key: "PROJECTS",
        },
      ],
    },
  },
  {
    path: "queues",
    loadChildren: () =>
      import("@intelease/web/queues").then((m) => m.WebQueuesModule),
    canActivate: [AuthGuardService],
    data: {
      title: "Queues",
      breadcrumbs: [
        {
          key: "QUEUES",
        },
      ],
    },
  },
  {
    path: "settings",
    loadChildren: () =>
      import("@@intelease/web/settings").then((m) => m.WebSettingsModule),
    canActivate: [AuthGuardService],
    data: {
      breadcrumbs: [
        {
          key: "ADExSETTINGS",
        },
      ],
    },
  },
  // {
  //   path: '',
  //   component: PagesComponent,
  //   children: [],
  // },
  {
    path: "miscellaneous",
    loadChildren: () =>
      import("@@intelease/web/miscellaneous").then(
        (m) => m.WebMiscellaneousModule
      ),
  },
  {
    path: "auth",
    loadChildren: () =>
      import("@@intelease/web/auth").then((m) => m.WebAuthModule),
    data: {
      title: "auth",
    },
  },
  {
    path: "**",
    redirectTo: "/miscellaneous/not-found",
  },
];

const config: ExtraOptions = {
  useHash: !environment.isBrowserEnv,
  initialNavigation: "enabledBlocking",
  preloadingStrategy: QuicklinkStrategy,
};

@NgModule({
  imports: [QuicklinkModule, RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
