/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ClientDataModel")
export class ClientDataModel {
  @JsonProperty("clientId", String, true)
  clientId?: string = "";

  @JsonProperty("customData", Any, true)
  customData?: {
    [key: string]: string;
  } = {};

  @JsonProperty("siteId", String, true)
  siteId?: string = "";

  @JsonProperty("sourceId", String, true)
  sourceId?: string = "";

  @JsonProperty("sourceSystem", Any, true)
  sourceSystem?: "C360" | "RIMS" = undefined;
}
