/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiDeleteRequestModel } from "../models/o-api-delete-request-model";
import { OApiReqAddNewProvisionModel } from "../models/o-api-req-add-new-provision-model";
import { OApiReqChangeReviewOperationModeRequestDtoModel } from "../models/o-api-req-change-review-operation-mode-request-dto-model";
import { OApiReqCreateCommentReplyDtoModel } from "../models/o-api-req-create-comment-reply-dto-model";
import { OApiReqCreateDocumentCommentDtoModel } from "../models/o-api-req-create-document-comment-dto-model";
import { OApiReqEditCommentDtoModel } from "../models/o-api-req-edit-comment-dto-model";
import { OApiReqFullDocOutlineViewModel } from "../models/o-api-req-full-doc-outline-view-model";
import { OApiReqMarkRecordCompletedModel } from "../models/o-api-req-mark-record-completed-model";
import { OApiReqMarkRecordFailedModel } from "../models/o-api-req-mark-record-failed-model";
import { OApiReqMarkRecordRejectedModel } from "../models/o-api-req-mark-record-rejected-model";
import { OApiReqProvisionInconsistenciesModel } from "../models/o-api-req-provision-inconsistencies-model";
import { OApiReqRemoveSharedFileRequestModel } from "../models/o-api-req-remove-shared-file-request-model";
import { OApiReqRevertRecordReviewStageModel } from "../models/o-api-req-revert-record-review-stage-model";
import { OApiReqShareEntityRequestModel } from "../models/o-api-req-share-entity-request-model";
import { OApiReqShareMultiEntitiesRequestModel } from "../models/o-api-req-share-multi-entities-request-model";
import { OApiReqUpdateAnchorProvisionModel } from "../models/o-api-req-update-anchor-provision-model";
import { OApiReqUpdateCustomTagsRequestDtoModel } from "../models/o-api-req-update-custom-tags-request-dto-model";
import { OApiRespAbstractNameSuggestionsDtoModel } from "../models/o-api-resp-abstract-name-suggestions-dto-model";
import { OApiRespChangeCustomTagsResponseDtoModel } from "../models/o-api-resp-change-custom-tags-response-dto-model";
import { OApiRespChangeReviewOperationModeResponseDtoModel } from "../models/o-api-resp-change-review-operation-mode-response-dto-model";
import { OApiRespCommentCountViewModel } from "../models/o-api-resp-comment-count-view-model";
import { OApiRespDocumentCommentViewModel } from "../models/o-api-resp-document-comment-view-model";
import { OApiRespEventsAbstractViewModel } from "../models/o-api-resp-events-abstract-view-model";
import { OApiRespFinalAbstractViewCompleteModel } from "../models/o-api-resp-final-abstract-view-complete-model";
import { OApiRespFormNameAbstractViewModel } from "../models/o-api-resp-form-name-abstract-view-model";
import { OApiRespFrontendPermissionTypeModel } from "../models/o-api-resp-frontend-permission-type-model";
import { OApiRespFullFinalAbstractViewModel } from "../models/o-api-resp-full-final-abstract-view-model";
import { OApiRespFullProvisionAndHighlightDtoModel } from "../models/o-api-resp-full-provision-and-highlight-dto-model";
import { OApiRespMinimalCommentViewModel } from "../models/o-api-resp-minimal-comment-view-model";
import { OApiRespMinimalFinalAbstractViewModel } from "../models/o-api-resp-minimal-final-abstract-view-model";
import { OApiRespMultiDocumentCommentViewModel } from "../models/o-api-resp-multi-document-comment-view-model";
import { OApiRespMultiEntityAllPermissionsFullDtoModel } from "../models/o-api-resp-multi-entity-all-permissions-full-dto-model";
import { OApiRespMultiEventsAbstractViewModel } from "../models/o-api-resp-multi-events-abstract-view-model";
import { OApiRespMultiFinalAbstractViewCompleteModel } from "../models/o-api-resp-multi-final-abstract-view-complete-model";
import { OApiRespMultiFormNameAbstractViewModel } from "../models/o-api-resp-multi-form-name-abstract-view-model";
import { OApiRespMultiFullFinalAbstractViewModel } from "../models/o-api-resp-multi-full-final-abstract-view-model";
import { OApiRespMultiFullNamedSearchFieldViewModel } from "../models/o-api-resp-multi-full-named-search-field-view-model";
import { OApiRespMultiMinimalFinalAbstractViewModel } from "../models/o-api-resp-multi-minimal-final-abstract-view-model";
import { OApiRespMultiMultiProvisionStringsAbstractViewModel } from "../models/o-api-resp-multi-multi-provision-strings-abstract-view-model";
import { OApiRespMultiMultiProvisionStringsExportAbstractViewModel } from "../models/o-api-resp-multi-multi-provision-strings-export-abstract-view-model";
import { OApiRespMultiMultiProvisionValuesAbstractViewModel } from "../models/o-api-resp-multi-multi-provision-values-abstract-view-model";
import { OApiRespMultiPermissionsAbstractViewV2Model } from "../models/o-api-resp-multi-permissions-abstract-view-v-2-model";
import { OApiRespMultiProvisionCategoryModel } from "../models/o-api-resp-multi-provision-category-model";
import { OApiRespMultiProvisionFormAbstractViewModel } from "../models/o-api-resp-multi-provision-form-abstract-view-model";
import { OApiRespMultiProvisionStringsAbstractViewModel } from "../models/o-api-resp-multi-provision-strings-abstract-view-model";
import { OApiRespMultiProvisionStringsExportAbstractViewModel } from "../models/o-api-resp-multi-provision-strings-export-abstract-view-model";
import { OApiRespMultiProvisionValuesAbstractViewModel } from "../models/o-api-resp-multi-provision-values-abstract-view-model";
import { OApiRespMultiRelatedDocsAbstractViewModel } from "../models/o-api-resp-multi-related-docs-abstract-view-model";
import { OApiRespMultiSearchTableAbstractsModel } from "../models/o-api-resp-multi-search-table-abstracts-model";
import { OApiRespMultiTableOfContentsViewModel } from "../models/o-api-resp-multi-table-of-contents-view-model";
import { OApiRespMultiUserRecordActivityDtoModel } from "../models/o-api-resp-multi-user-record-activity-dto-model";
import { OApiRespPermissionsAbstractViewV2Model } from "../models/o-api-resp-permissions-abstract-view-v-2-model";
import { OApiRespProvisionFormAbstractViewModel } from "../models/o-api-resp-provision-form-abstract-view-model";
import { OApiRespProvisionValuesAbstractViewModel } from "../models/o-api-resp-provision-values-abstract-view-model";
import { OApiRespRecordFailedModel } from "../models/o-api-resp-record-failed-model";
import { OApiRespRecordLockedModel } from "../models/o-api-resp-record-locked-model";
import { OApiRespRecordRejectedModel } from "../models/o-api-resp-record-rejected-model";
import { OApiRespRecordReviewStageRevertedModel } from "../models/o-api-resp-record-review-stage-reverted-model";
import { OApiRespRelatedDocsAbstractViewModel } from "../models/o-api-resp-related-docs-abstract-view-model";
import { OApiRespRemoveSharedModel } from "../models/o-api-resp-remove-shared-model";
import { OApiRespSharedModel } from "../models/o-api-resp-shared-model";
import { OApiRespTableOfContentsViewModel } from "../models/o-api-resp-table-of-contents-view-model";
import { OApiSingleEntityNoDataResponseModel } from "../models/o-api-single-entity-no-data-response-model";
import { RequestModel } from "../models/request-model";

@Injectable({
  providedIn: "root",
})
export class RecordService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAll5
   */
  static readonly GetAll5Path =
    "/api/v1/finalDocSets/{recordUid}/documents/{docUid}/comments";

  /**
   * List of comments for a document of a record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll5()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll5$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespMultiDocumentCommentViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.GetAll5Path,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiDocumentCommentViewModel>;
        })
      );
  }

  /**
   * List of comments for a document of a record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll5$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll5(params?: {}): Observable<OApiRespMultiDocumentCommentViewModel> {
    return this.getAll5$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiDocumentCommentViewModel>) =>
          r.body as OApiRespMultiDocumentCommentViewModel
      )
    );
  }

  /**
   * Path part for operation create6
   */
  static readonly Create6Path =
    "/api/v1/finalDocSets/{recordUid}/documents/{docUid}/comments";

  /**
   * Create a comment for a document of a record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create6()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create6$Response(params: {
    body: OApiReqCreateDocumentCommentDtoModel;
  }): Observable<StrictHttpResponse<OApiRespDocumentCommentViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.Create6Path,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespDocumentCommentViewModel>;
        })
      );
  }

  /**
   * Create a comment for a document of a record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create6$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create6(params: {
    body: OApiReqCreateDocumentCommentDtoModel;
  }): Observable<OApiRespDocumentCommentViewModel> {
    return this.create6$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespDocumentCommentViewModel>) =>
          r.body as OApiRespDocumentCommentViewModel
      )
    );
  }

  /**
   * Path part for operation edit5
   */
  static readonly Edit5Path =
    "/api/v1/finalDocSets/{recordUid}/documents/{docUid}/comments/{commentUid}";

  /**
   * Edit a comment for a document of a record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `edit5()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  edit5$Response(params: {
    body: OApiReqEditCommentDtoModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(this.rootUrl, RecordService.Edit5Path, "put");
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Edit a comment for a document of a record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `edit5$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  edit5(params: {
    body: OApiReqEditCommentDtoModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.edit5$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation delete1
   */
  static readonly Delete1Path =
    "/api/v1/finalDocSets/{recordUid}/documents/{docUid}/comments/{commentUid}";

  /**
   * Delete a comment for a document of a record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete1()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete1$Response(params?: {}): Observable<
    StrictHttpResponse<OApiSingleEntityNoDataResponseModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.Delete1Path,
      "delete"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Delete a comment for a document of a record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `delete1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete1(params?: {}): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.delete1$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation getCommentCount
   */
  static readonly GetCommentCountPath =
    "/api/v1/records/{recordUid}/comments/count";

  /**
   * Total comment count in a record.
   *
   * Get the total count of comments in a record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCommentCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommentCount$Response(params: {
    /**
     * The unique id of a record
     */
    recordUid: string;
  }): Observable<StrictHttpResponse<OApiRespCommentCountViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.GetCommentCountPath,
      "get"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespCommentCountViewModel>;
        })
      );
  }

  /**
   * Total comment count in a record.
   *
   * Get the total count of comments in a record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCommentCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommentCount(params: {
    /**
     * The unique id of a record
     */
    recordUid: string;
  }): Observable<OApiRespCommentCountViewModel> {
    return this.getCommentCount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespCommentCountViewModel>) =>
          r.body as OApiRespCommentCountViewModel
      )
    );
  }

  /**
   * Path part for operation replyTo
   */
  static readonly ReplyToPath =
    "/api/v1/finalDocSets/{recordUid}/documents/{docUid}/comments/{commentUid}/replied";

  /**
   * Reply to a comment for a document of a record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replyTo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replyTo$Response(params: {
    body: OApiReqCreateCommentReplyDtoModel;
  }): Observable<StrictHttpResponse<OApiRespMinimalCommentViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.ReplyToPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMinimalCommentViewModel>;
        })
      );
  }

  /**
   * Reply to a comment for a document of a record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `replyTo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replyTo(params: {
    body: OApiReqCreateCommentReplyDtoModel;
  }): Observable<OApiRespMinimalCommentViewModel> {
    return this.replyTo$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMinimalCommentViewModel>) =>
          r.body as OApiRespMinimalCommentViewModel
      )
    );
  }

  /**
   * Path part for operation getRecord
   */
  static readonly GetRecordPath = "/api/v1/finalDocSets/{recordUid}";

  /**
   * Get a record.
   *
   * Get a view of an abstract. Do not send zip format requests to this endpoint. Input can contain a provisionFormUid e.g. b/c need to export several abstracts, all with same provisionForm.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecord()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecord$Response(params: {
    /**
     * The unique id of the record to fetch.
     */
    recordUid: string;

    /**
     * The view of the record to fetch.
     */
    view:
      | "COMPLETE"
      | "EVENTS"
      | "FORM_NAME"
      | "FULL"
      | "MINIMAL"
      | "PROVISION_VALUES"
      | "MULTI_PROVISION_STRINGS"
      | "MULTI_PROVISION_STRINGS_EXPORT"
      | "MULTI_PROVISION_STRINGS_NO_TABLES_EXPORT"
      | "MULTI_PROVISION_STRINGS_ONLY_TABLES_EXPORT"
      | "MULTI_PROVISION_VALUES"
      | "PROVISION_FORM"
      | "RELATED_DOCS"
      | "PERMISSIONS"
      | "TABLE_OF_CONTENTS"
      | "NONE";

    /**
     * The unique id of the provision form for this record.
     */
    provisionFormUid?: string;

    /**
     * Whether to exclude empty provisions (when exporting).
     */
    excludeEmptyProvisions?: boolean;

    /**
     * If including empty provisions (when exporting), the text to show for them.
     */
    emptyProvisionText?: string;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullFinalAbstractViewModel
      | OApiRespFormNameAbstractViewModel
      | OApiRespMultiProvisionStringsAbstractViewModel
      | OApiRespMultiProvisionStringsExportAbstractViewModel
      | OApiRespFinalAbstractViewCompleteModel
      | OApiRespEventsAbstractViewModel
      | OApiRespRelatedDocsAbstractViewModel
      | OApiRespPermissionsAbstractViewV2Model
      | OApiRespMinimalFinalAbstractViewModel
      | OApiRespProvisionValuesAbstractViewModel
      | OApiRespTableOfContentsViewModel
      | OApiRespProvisionValuesAbstractViewModel
      | OApiRespProvisionFormAbstractViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.GetRecordPath,
      "get"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.query("view", params.view, {});
      rb.query("provisionFormUid", params.provisionFormUid, {});
      rb.query("excludeEmptyProvisions", params.excludeEmptyProvisions, {});
      rb.query("emptyProvisionText", params.emptyProvisionText, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >;
        })
      );
  }

  /**
   * Get a record.
   *
   * Get a view of an abstract. Do not send zip format requests to this endpoint. Input can contain a provisionFormUid e.g. b/c need to export several abstracts, all with same provisionForm.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecord$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecord(params: {
    /**
     * The unique id of the record to fetch.
     */
    recordUid: string;

    /**
     * The view of the record to fetch.
     */
    view:
      | "COMPLETE"
      | "EVENTS"
      | "FORM_NAME"
      | "FULL"
      | "MINIMAL"
      | "PROVISION_VALUES"
      | "MULTI_PROVISION_STRINGS"
      | "MULTI_PROVISION_STRINGS_EXPORT"
      | "MULTI_PROVISION_STRINGS_NO_TABLES_EXPORT"
      | "MULTI_PROVISION_STRINGS_ONLY_TABLES_EXPORT"
      | "MULTI_PROVISION_VALUES"
      | "PROVISION_FORM"
      | "RELATED_DOCS"
      | "PERMISSIONS"
      | "TABLE_OF_CONTENTS"
      | "NONE";

    /**
     * The unique id of the provision form for this record.
     */
    provisionFormUid?: string;

    /**
     * Whether to exclude empty provisions (when exporting).
     */
    excludeEmptyProvisions?: boolean;

    /**
     * If including empty provisions (when exporting), the text to show for them.
     */
    emptyProvisionText?: string;
  }): Observable<
    | OApiRespFullFinalAbstractViewModel
    | OApiRespFormNameAbstractViewModel
    | OApiRespMultiProvisionStringsAbstractViewModel
    | OApiRespMultiProvisionStringsExportAbstractViewModel
    | OApiRespFinalAbstractViewCompleteModel
    | OApiRespEventsAbstractViewModel
    | OApiRespRelatedDocsAbstractViewModel
    | OApiRespPermissionsAbstractViewV2Model
    | OApiRespMinimalFinalAbstractViewModel
    | OApiRespProvisionValuesAbstractViewModel
    | OApiRespTableOfContentsViewModel
    | OApiRespProvisionValuesAbstractViewModel
    | OApiRespProvisionFormAbstractViewModel
  > {
    return this.getRecord$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >
        ) =>
          r.body as
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
      )
    );
  }

  /**
   * Path part for operation deleteRecord
   */
  static readonly DeleteRecordPath = "/api/v1/finalDocSets/{recordUid}";

  /**
   * Delete a record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRecord()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRecord$Response(params: {
    /**
     * The view of the deleted record to return (NONE means no return view).
     */
    view:
      | "COMPLETE"
      | "EVENTS"
      | "FORM_NAME"
      | "FULL"
      | "MINIMAL"
      | "PROVISION_VALUES"
      | "MULTI_PROVISION_STRINGS"
      | "MULTI_PROVISION_STRINGS_EXPORT"
      | "MULTI_PROVISION_STRINGS_NO_TABLES_EXPORT"
      | "MULTI_PROVISION_STRINGS_ONLY_TABLES_EXPORT"
      | "MULTI_PROVISION_VALUES"
      | "PROVISION_FORM"
      | "RELATED_DOCS"
      | "PERMISSIONS"
      | "TABLE_OF_CONTENTS"
      | "NONE";
    recordUid: string;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullFinalAbstractViewModel
      | OApiRespFormNameAbstractViewModel
      | OApiRespMultiProvisionStringsAbstractViewModel
      | OApiRespMultiProvisionStringsExportAbstractViewModel
      | OApiRespFinalAbstractViewCompleteModel
      | OApiRespEventsAbstractViewModel
      | OApiRespRelatedDocsAbstractViewModel
      | OApiRespPermissionsAbstractViewV2Model
      | OApiRespMinimalFinalAbstractViewModel
      | OApiRespProvisionValuesAbstractViewModel
      | OApiRespTableOfContentsViewModel
      | OApiRespMultiProvisionValuesAbstractViewModel
      | OApiRespProvisionFormAbstractViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.DeleteRecordPath,
      "delete"
    );
    if (params) {
      rb.query("view", params.view, {});
      rb.path("recordUid", params.recordUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >;
        })
      );
  }

  /**
   * Delete a record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRecord$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRecord(params: {
    /**
     * The view of the deleted record to return (NONE means no return view).
     */
    view:
      | "COMPLETE"
      | "EVENTS"
      | "FORM_NAME"
      | "FULL"
      | "MINIMAL"
      | "PROVISION_VALUES"
      | "MULTI_PROVISION_STRINGS"
      | "MULTI_PROVISION_STRINGS_EXPORT"
      | "MULTI_PROVISION_STRINGS_NO_TABLES_EXPORT"
      | "MULTI_PROVISION_STRINGS_ONLY_TABLES_EXPORT"
      | "MULTI_PROVISION_VALUES"
      | "PROVISION_FORM"
      | "RELATED_DOCS"
      | "PERMISSIONS"
      | "TABLE_OF_CONTENTS"
      | "NONE";
    recordUid: string;
  }): Observable<
    | OApiRespFullFinalAbstractViewModel
    | OApiRespFormNameAbstractViewModel
    | OApiRespMultiProvisionStringsAbstractViewModel
    | OApiRespMultiProvisionStringsExportAbstractViewModel
    | OApiRespFinalAbstractViewCompleteModel
    | OApiRespEventsAbstractViewModel
    | OApiRespRelatedDocsAbstractViewModel
    | OApiRespPermissionsAbstractViewV2Model
    | OApiRespMinimalFinalAbstractViewModel
    | OApiRespProvisionValuesAbstractViewModel
    | OApiRespTableOfContentsViewModel
    | OApiRespMultiProvisionValuesAbstractViewModel
    | OApiRespProvisionFormAbstractViewModel
  > {
    return this.deleteRecord$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >
        ) =>
          r.body as
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
      )
    );
  }

  /**
   * Path part for operation deleteRecords
   */
  static readonly DeleteRecordsPath = "/api/v1/deleteBatch/finalDocSets";

  /**
   * Delete list of records.
   *
   * Delete the list of records specified by the ids in the request body.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRecords()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteRecords$Response(params: {
    /**
     * Data required to delete records.
     */
    body: OApiDeleteRequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.DeleteRecordsPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Delete list of records.
   *
   * Delete the list of records specified by the ids in the request body.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRecords$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteRecords(params: {
    /**
     * Data required to delete records.
     */
    body: OApiDeleteRequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.deleteRecords$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation acquireLock
   */
  static readonly AcquireLockPath = "/api/v1/records/{recordUid}/acquire-lock";

  /**
   * Acquire Lock on a record to prevent editing by others.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acquireLock()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  acquireLock$Response(params: {
    recordUid: string;
    body?: RequestModel;
  }): Observable<StrictHttpResponse<OApiRespRecordLockedModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.AcquireLockPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespRecordLockedModel>;
        })
      );
  }

  /**
   * Acquire Lock on a record to prevent editing by others.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acquireLock$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  acquireLock(params: {
    recordUid: string;
    body?: RequestModel;
  }): Observable<OApiRespRecordLockedModel> {
    return this.acquireLock$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespRecordLockedModel>) =>
          r.body as OApiRespRecordLockedModel
      )
    );
  }

  /**
   * Path part for operation addNewProvisionToAbstractForm
   */
  static readonly AddNewProvisionToAbstractFormPath =
    "/api/v1/finalDocSets/{recordUid}/newProvision";

  /**
   * Add new provision to the record and its form structure.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewProvisionToAbstractForm()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  addNewProvisionToAbstractForm$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullFinalAbstractViewModel
      | OApiRespFormNameAbstractViewModel
      | OApiRespMultiProvisionStringsAbstractViewModel
      | OApiRespMultiProvisionStringsExportAbstractViewModel
      | OApiRespFinalAbstractViewCompleteModel
      | OApiRespEventsAbstractViewModel
      | OApiRespRelatedDocsAbstractViewModel
      | OApiRespPermissionsAbstractViewV2Model
      | OApiRespMinimalFinalAbstractViewModel
      | OApiRespProvisionValuesAbstractViewModel
      | OApiRespTableOfContentsViewModel
      | OApiRespMultiProvisionValuesAbstractViewModel
      | OApiRespProvisionFormAbstractViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.AddNewProvisionToAbstractFormPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >;
        })
      );
  }

  /**
   * Add new provision to the record and its form structure.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewProvisionToAbstractForm$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  addNewProvisionToAbstractForm(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespFullFinalAbstractViewModel
    | OApiRespFormNameAbstractViewModel
    | OApiRespMultiProvisionStringsAbstractViewModel
    | OApiRespMultiProvisionStringsExportAbstractViewModel
    | OApiRespFinalAbstractViewCompleteModel
    | OApiRespEventsAbstractViewModel
    | OApiRespRelatedDocsAbstractViewModel
    | OApiRespPermissionsAbstractViewV2Model
    | OApiRespMinimalFinalAbstractViewModel
    | OApiRespProvisionValuesAbstractViewModel
    | OApiRespTableOfContentsViewModel
    | OApiRespMultiProvisionValuesAbstractViewModel
    | OApiRespProvisionFormAbstractViewModel
  > {
    return this.addNewProvisionToAbstractForm$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >
        ) =>
          r.body as
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
      )
    );
  }

  /**
   * Path part for operation addNewProvisionToAbstractFormV2
   */
  static readonly AddNewProvisionToAbstractFormV2Path =
    "/api/v2/finalDocSets/{recordUid}/newProvision";

  /**
   * Add new provision to the record and its form structure.
   *
   * Add a new provision to an existing record, and update the form structure
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewProvisionToAbstractFormV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewProvisionToAbstractFormV2$Response(params: {
    /**
     * The unique id of the record for which a new provision is being added.
     */
    recordUid: string;

    /**
     * The data the user is sending for adding a new provision.
     */
    body: OApiReqAddNewProvisionModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.AddNewProvisionToAbstractFormV2Path,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * Add new provision to the record and its form structure.
   *
   * Add a new provision to an existing record, and update the form structure
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewProvisionToAbstractFormV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewProvisionToAbstractFormV2(params: {
    /**
     * The unique id of the record for which a new provision is being added.
     */
    recordUid: string;

    /**
     * The data the user is sending for adding a new provision.
     */
    body: OApiReqAddNewProvisionModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.addNewProvisionToAbstractFormV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation completeReview
   */
  static readonly CompleteReviewPath = "/api/v1/record/complete";

  /**
   * Complete a record review.
   *
   * If it succeeds, it returns status 200 and details information about the completed record.If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeReview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeReview$Response(params: {
    /**
     * The record uid provided by the user, for completing a record.
     */
    body: OApiReqMarkRecordCompletedModel;
  }): Observable<StrictHttpResponse<OApiRespRecordReviewStageRevertedModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.CompleteReviewPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespRecordReviewStageRevertedModel>;
        })
      );
  }

  /**
   * Complete a record review.
   *
   * If it succeeds, it returns status 200 and details information about the completed record.If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `completeReview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeReview(params: {
    /**
     * The record uid provided by the user, for completing a record.
     */
    body: OApiReqMarkRecordCompletedModel;
  }): Observable<OApiRespRecordReviewStageRevertedModel> {
    return this.completeReview$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespRecordReviewStageRevertedModel>) =>
          r.body as OApiRespRecordReviewStageRevertedModel
      )
    );
  }

  /**
   * Path part for operation editAbstractAbstractorCode
   */
  static readonly EditAbstractAbstractorCodePath =
    "/api/v1/finalDocSets/{recordUid}/abstractorCode";

  /**
   * Change record's abstractor code.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editAbstractAbstractorCode()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editAbstractAbstractorCode$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullFinalAbstractViewModel
      | OApiRespFormNameAbstractViewModel
      | OApiRespMultiProvisionStringsAbstractViewModel
      | OApiRespMultiProvisionStringsExportAbstractViewModel
      | OApiRespFinalAbstractViewCompleteModel
      | OApiRespEventsAbstractViewModel
      | OApiRespRelatedDocsAbstractViewModel
      | OApiRespPermissionsAbstractViewV2Model
      | OApiRespMinimalFinalAbstractViewModel
      | OApiRespProvisionValuesAbstractViewModel
      | OApiRespTableOfContentsViewModel
      | OApiRespMultiProvisionValuesAbstractViewModel
      | OApiRespProvisionFormAbstractViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.EditAbstractAbstractorCodePath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >;
        })
      );
  }

  /**
   * Change record's abstractor code.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editAbstractAbstractorCode$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editAbstractAbstractorCode(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespFullFinalAbstractViewModel
    | OApiRespFormNameAbstractViewModel
    | OApiRespMultiProvisionStringsAbstractViewModel
    | OApiRespMultiProvisionStringsExportAbstractViewModel
    | OApiRespFinalAbstractViewCompleteModel
    | OApiRespEventsAbstractViewModel
    | OApiRespRelatedDocsAbstractViewModel
    | OApiRespPermissionsAbstractViewV2Model
    | OApiRespMinimalFinalAbstractViewModel
    | OApiRespProvisionValuesAbstractViewModel
    | OApiRespTableOfContentsViewModel
    | OApiRespMultiProvisionValuesAbstractViewModel
    | OApiRespProvisionFormAbstractViewModel
  > {
    return this.editAbstractAbstractorCode$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >
        ) =>
          r.body as
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
      )
    );
  }

  /**
   * Path part for operation editAbstractName
   */
  static readonly EditAbstractNamePath =
    "/api/v1/finalDocSets/{recordUid}/name";

  /**
   * Change record's name / status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editAbstractName()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editAbstractName$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullFinalAbstractViewModel
      | OApiRespFormNameAbstractViewModel
      | OApiRespMultiProvisionStringsAbstractViewModel
      | OApiRespMultiProvisionStringsExportAbstractViewModel
      | OApiRespFinalAbstractViewCompleteModel
      | OApiRespEventsAbstractViewModel
      | OApiRespRelatedDocsAbstractViewModel
      | OApiRespPermissionsAbstractViewV2Model
      | OApiRespMinimalFinalAbstractViewModel
      | OApiRespProvisionValuesAbstractViewModel
      | OApiRespTableOfContentsViewModel
      | OApiRespMultiProvisionValuesAbstractViewModel
      | OApiRespProvisionFormAbstractViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.EditAbstractNamePath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >;
        })
      );
  }

  /**
   * Change record's name / status.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editAbstractName$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editAbstractName(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespFullFinalAbstractViewModel
    | OApiRespFormNameAbstractViewModel
    | OApiRespMultiProvisionStringsAbstractViewModel
    | OApiRespMultiProvisionStringsExportAbstractViewModel
    | OApiRespFinalAbstractViewCompleteModel
    | OApiRespEventsAbstractViewModel
    | OApiRespRelatedDocsAbstractViewModel
    | OApiRespPermissionsAbstractViewV2Model
    | OApiRespMinimalFinalAbstractViewModel
    | OApiRespProvisionValuesAbstractViewModel
    | OApiRespTableOfContentsViewModel
    | OApiRespMultiProvisionValuesAbstractViewModel
    | OApiRespProvisionFormAbstractViewModel
  > {
    return this.editAbstractName$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >
        ) =>
          r.body as
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
      )
    );
  }

  /**
   * Path part for operation editAbstractReviewOperationMode
   */
  static readonly EditAbstractReviewOperationModePath =
    "/api/v1/finalDocSets/{recordUid}/reviewOperationMode";

  /**
   * Change record's review operation mode.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editAbstractReviewOperationMode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editAbstractReviewOperationMode$Response(params: {
    body: OApiReqChangeReviewOperationModeRequestDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespChangeReviewOperationModeResponseDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.EditAbstractReviewOperationModePath,
      "put"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespChangeReviewOperationModeResponseDtoModel>;
        })
      );
  }

  /**
   * Change record's review operation mode.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editAbstractReviewOperationMode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editAbstractReviewOperationMode(params: {
    body: OApiReqChangeReviewOperationModeRequestDtoModel;
  }): Observable<OApiRespChangeReviewOperationModeResponseDtoModel> {
    return this.editAbstractReviewOperationMode$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<OApiRespChangeReviewOperationModeResponseDtoModel>
        ) => r.body as OApiRespChangeReviewOperationModeResponseDtoModel
      )
    );
  }

  /**
   * Path part for operation editAbstractStatus
   */
  static readonly EditAbstractStatusPath =
    "/api/v1/finalDocSets/{recordUid}/status";

  /**
   * Change record's status.
   *
   * Given a unique id of a record, change its status as specified.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editAbstractStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editAbstractStatus$Response(params: {
    recordUid: string;

    /**
     * Data needed to change a record's status.
     */
    body: OApiDeleteRequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullFinalAbstractViewModel
      | OApiRespFormNameAbstractViewModel
      | OApiRespMultiProvisionStringsAbstractViewModel
      | OApiRespMultiProvisionStringsExportAbstractViewModel
      | OApiRespFinalAbstractViewCompleteModel
      | OApiRespEventsAbstractViewModel
      | OApiRespRelatedDocsAbstractViewModel
      | OApiRespPermissionsAbstractViewV2Model
      | OApiRespMinimalFinalAbstractViewModel
      | OApiRespProvisionValuesAbstractViewModel
      | OApiRespTableOfContentsViewModel
      | OApiRespMultiProvisionValuesAbstractViewModel
      | OApiRespProvisionFormAbstractViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.EditAbstractStatusPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >;
        })
      );
  }

  /**
   * Change record's status.
   *
   * Given a unique id of a record, change its status as specified.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editAbstractStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editAbstractStatus(params: {
    recordUid: string;

    /**
     * Data needed to change a record's status.
     */
    body: OApiDeleteRequestModel;
  }): Observable<
    | OApiRespFullFinalAbstractViewModel
    | OApiRespFormNameAbstractViewModel
    | OApiRespMultiProvisionStringsAbstractViewModel
    | OApiRespMultiProvisionStringsExportAbstractViewModel
    | OApiRespFinalAbstractViewCompleteModel
    | OApiRespEventsAbstractViewModel
    | OApiRespRelatedDocsAbstractViewModel
    | OApiRespPermissionsAbstractViewV2Model
    | OApiRespMinimalFinalAbstractViewModel
    | OApiRespProvisionValuesAbstractViewModel
    | OApiRespTableOfContentsViewModel
    | OApiRespMultiProvisionValuesAbstractViewModel
    | OApiRespProvisionFormAbstractViewModel
  > {
    return this.editAbstractStatus$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >
        ) =>
          r.body as
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
      )
    );
  }

  /**
   * Path part for operation editRecordCustomTags
   */
  static readonly EditRecordCustomTagsPath =
    "/api/v1/records/{recordUid}/custom-tags";

  /**
   * Change a record's custom tags.
   *
   * If successfully update the record's custom tags, return 200. This can also save the custom tag to the account, if it's brand new for it.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editRecordCustomTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editRecordCustomTags$Response(params: {
    recordUid: string;
    body: OApiReqUpdateCustomTagsRequestDtoModel;
  }): Observable<StrictHttpResponse<OApiRespChangeCustomTagsResponseDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.EditRecordCustomTagsPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespChangeCustomTagsResponseDtoModel>;
        })
      );
  }

  /**
   * Change a record's custom tags.
   *
   * If successfully update the record's custom tags, return 200. This can also save the custom tag to the account, if it's brand new for it.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editRecordCustomTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editRecordCustomTags(params: {
    recordUid: string;
    body: OApiReqUpdateCustomTagsRequestDtoModel;
  }): Observable<OApiRespChangeCustomTagsResponseDtoModel> {
    return this.editRecordCustomTags$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespChangeCustomTagsResponseDtoModel>) =>
          r.body as OApiRespChangeCustomTagsResponseDtoModel
      )
    );
  }

  /**
   * Path part for operation failRecord
   */
  static readonly FailRecordPath = "/api/v1/record/fail";

  /**
   * Move a record to its queue's failed stage.
   *
   * If it succeeds, it returns status 200 and detailed information about the failed record.If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `failRecord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  failRecord$Response(params: {
    /**
     * The record uid provided by the user, for failing the record.
     */
    body: OApiReqMarkRecordFailedModel;
  }): Observable<StrictHttpResponse<OApiRespRecordFailedModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.FailRecordPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespRecordFailedModel>;
        })
      );
  }

  /**
   * Move a record to its queue's failed stage.
   *
   * If it succeeds, it returns status 200 and detailed information about the failed record.If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `failRecord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  failRecord(params: {
    /**
     * The record uid provided by the user, for failing the record.
     */
    body: OApiReqMarkRecordFailedModel;
  }): Observable<OApiRespRecordFailedModel> {
    return this.failRecord$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespRecordFailedModel>) =>
          r.body as OApiRespRecordFailedModel
      )
    );
  }

  /**
   * Path part for operation rejectReview
   */
  static readonly RejectReviewPath = "/api/v1/record/reject";

  /**
   * Reject a record review.
   *
   * If it succeeds, it returns status 200 and detailed information about the rejected record.If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectReview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rejectReview$Response(params: {
    /**
     * The record uid provided by the user, for rejecting a record.
     */
    body: OApiReqMarkRecordRejectedModel;
  }): Observable<StrictHttpResponse<OApiRespRecordRejectedModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.RejectReviewPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespRecordRejectedModel>;
        })
      );
  }

  /**
   * Reject a record review.
   *
   * If it succeeds, it returns status 200 and detailed information about the rejected record.If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rejectReview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rejectReview(params: {
    /**
     * The record uid provided by the user, for rejecting a record.
     */
    body: OApiReqMarkRecordRejectedModel;
  }): Observable<OApiRespRecordRejectedModel> {
    return this.rejectReview$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespRecordRejectedModel>) =>
          r.body as OApiRespRecordRejectedModel
      )
    );
  }

  /**
   * Path part for operation releaseLock
   */
  static readonly ReleaseLockPath = "/api/v1/records/{recordUid}/release-lock";

  /**
   * Release the lock on a record to allow locking and editing by others.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `releaseLock()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  releaseLock$Response(params: {
    recordUid: string;
    body?: RequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.ReleaseLockPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Release the lock on a record to allow locking and editing by others.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `releaseLock$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  releaseLock(params: {
    recordUid: string;
    body?: RequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.releaseLock$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation revertReviewStage
   */
  static readonly RevertReviewStagePath = "/api/v1/record/revert-review-stage";

  /**
   * Revert a record's review stage.
   *
   * If it succeeds, it returns status 200 and detailed information about the review stage reverted record.If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revertReviewStage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revertReviewStage$Response(params: {
    /**
     * The record uid and review stage provided by the user, for reverting a record's review stage.
     */
    body: OApiReqRevertRecordReviewStageModel;
  }): Observable<StrictHttpResponse<OApiRespRecordRejectedModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.RevertReviewStagePath,
      "put"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespRecordRejectedModel>;
        })
      );
  }

  /**
   * Revert a record's review stage.
   *
   * If it succeeds, it returns status 200 and detailed information about the review stage reverted record.If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `revertReviewStage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revertReviewStage(params: {
    /**
     * The record uid and review stage provided by the user, for reverting a record's review stage.
     */
    body: OApiReqRevertRecordReviewStageModel;
  }): Observable<OApiRespRecordRejectedModel> {
    return this.revertReviewStage$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespRecordRejectedModel>) =>
          r.body as OApiRespRecordRejectedModel
      )
    );
  }

  /**
   * Path part for operation updateAnchorProvision
   */
  static readonly UpdateAnchorProvisionPath =
    "/api/v1/records/{recordUid}/anchor-provision";

  /**
   * Change the anchor provision for the record.
   *
   * Return 200 if successfully changed the anchor provision for the record, else 400
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAnchorProvision()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnchorProvision$Response(params: {
    /**
     * The unique id of the record for which a new provision is being added.
     */
    recordUid: string;

    /**
     * The data the user is sending for changing the anchor provision.
     */
    body: OApiReqUpdateAnchorProvisionModel;
  }): Observable<StrictHttpResponse<OApiReqProvisionInconsistenciesModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.UpdateAnchorProvisionPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiReqProvisionInconsistenciesModel>;
        })
      );
  }

  /**
   * Change the anchor provision for the record.
   *
   * Return 200 if successfully changed the anchor provision for the record, else 400
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAnchorProvision$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnchorProvision(params: {
    /**
     * The unique id of the record for which a new provision is being added.
     */
    recordUid: string;

    /**
     * The data the user is sending for changing the anchor provision.
     */
    body: OApiReqUpdateAnchorProvisionModel;
  }): Observable<OApiReqProvisionInconsistenciesModel> {
    return this.updateAnchorProvision$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiReqProvisionInconsistenciesModel>) =>
          r.body as OApiReqProvisionInconsistenciesModel
      )
    );
  }

  /**
   * Path part for operation exportBatchTsvColliers
   */
  static readonly ExportBatchTsvColliersPath =
    "/api/v1/export/wt-tsv/finalDocSets";

  /**
   * Export a batch of abstracts as several tsv files, in a format needed for Colliers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportBatchTsvColliers()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  exportBatchTsvColliers$Response(params?: {
    body?: RequestModel;
  }): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.ExportBatchTsvColliersPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/zip",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export a batch of abstracts as several tsv files, in a format needed for Colliers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportBatchTsvColliers$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  exportBatchTsvColliers(params?: { body?: RequestModel }): Observable<any> {
    return this.exportBatchTsvColliers$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getAbstractNamesToNumber
   */
  static readonly GetAbstractNamesToNumberPath = "/api/v1/finalDocSets/names";

  /**
   * Get record names to number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbstractNamesToNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbstractNamesToNumber$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespAbstractNameSuggestionsDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.GetAbstractNamesToNumberPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespAbstractNameSuggestionsDtoModel>;
        })
      );
  }

  /**
   * Get record names to number.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbstractNamesToNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbstractNamesToNumber(params?: {}): Observable<OApiRespAbstractNameSuggestionsDtoModel> {
    return this.getAbstractNamesToNumber$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespAbstractNameSuggestionsDtoModel>) =>
          r.body as OApiRespAbstractNameSuggestionsDtoModel
      )
    );
  }

  /**
   * Path part for operation getComplete
   */
  static readonly GetCompletePath = "/api/v2/finalDocSets/{recordUid}";

  /**
   * Get basically the complete info for an abstract (useful for e.g. abstract-review page).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getComplete()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComplete$Response(params: {
    recordUid: string;
  }): Observable<StrictHttpResponse<OApiRespFinalAbstractViewCompleteModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.GetCompletePath,
      "get"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFinalAbstractViewCompleteModel>;
        })
      );
  }

  /**
   * Get basically the complete info for an abstract (useful for e.g. abstract-review page).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getComplete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComplete(params: {
    recordUid: string;
  }): Observable<OApiRespFinalAbstractViewCompleteModel> {
    return this.getComplete$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFinalAbstractViewCompleteModel>) =>
          r.body as OApiRespFinalAbstractViewCompleteModel
      )
    );
  }

  /**
   * Path part for operation getCurrentUserPermission3
   */
  static readonly GetCurrentUserPermission3Path =
    "/api/v1/finalDocSets/{recordUid}/currentUser/permission";

  /**
   * Get current user's permissions for a record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserPermission3()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserPermission3$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespFrontendPermissionTypeModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.GetCurrentUserPermission3Path,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFrontendPermissionTypeModel>;
        })
      );
  }

  /**
   * Get current user's permissions for a record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserPermission3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserPermission3(params?: {}): Observable<OApiRespFrontendPermissionTypeModel> {
    return this.getCurrentUserPermission3$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFrontendPermissionTypeModel>) =>
          r.body as OApiRespFrontendPermissionTypeModel
      )
    );
  }

  /**
   * Path part for operation getPermissions4
   */
  static readonly GetPermissions4Path =
    "/api/v1/finalDocSets/{recordUid}/permissions";

  /**
   * Get permissions for a record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermissions4()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissions4$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespMultiEntityAllPermissionsFullDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.GetPermissions4Path,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiEntityAllPermissionsFullDtoModel>;
        })
      );
  }

  /**
   * Get permissions for a record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPermissions4$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissions4(params?: {}): Observable<OApiRespMultiEntityAllPermissionsFullDtoModel> {
    return this.getPermissions4$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<OApiRespMultiEntityAllPermissionsFullDtoModel>
        ) => r.body as OApiRespMultiEntityAllPermissionsFullDtoModel
      )
    );
  }

  /**
   * Path part for operation getPossibleColumns
   */
  static readonly GetPossibleColumnsPath = "/api/v1/finalDocSets/provisions";

  /**
   * Get the possible provisions for abstracts for this account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPossibleColumns()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPossibleColumns$Response(params: {
    view: "FULL" | "NONE";
  }): Observable<
    StrictHttpResponse<OApiRespMultiFullNamedSearchFieldViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.GetPossibleColumnsPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiFullNamedSearchFieldViewModel>;
        })
      );
  }

  /**
   * Get the possible provisions for abstracts for this account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPossibleColumns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPossibleColumns(params: {
    view: "FULL" | "NONE";
  }): Observable<OApiRespMultiFullNamedSearchFieldViewModel> {
    return this.getPossibleColumns$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiFullNamedSearchFieldViewModel>) =>
          r.body as OApiRespMultiFullNamedSearchFieldViewModel
      )
    );
  }

  /**
   * Path part for operation getRecentRecords
   */
  static readonly GetRecentRecordsPath = "/api/v1/recent-records";

  /**
   * Returns user recent records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecentRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentRecords$Response(params?: {
    page?: number;
    perPage?: number;
  }): Observable<StrictHttpResponse<OApiRespMultiUserRecordActivityDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.GetRecentRecordsPath,
      "get"
    );
    if (params) {
      rb.query("page", params.page, {});
      rb.query("perPage", params.perPage, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiUserRecordActivityDtoModel>;
        })
      );
  }

  /**
   * Returns user recent records.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecentRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentRecords(params?: {
    page?: number;
    perPage?: number;
  }): Observable<OApiRespMultiUserRecordActivityDtoModel> {
    return this.getRecentRecords$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiUserRecordActivityDtoModel>) =>
          r.body as OApiRespMultiUserRecordActivityDtoModel
      )
    );
  }

  /**
   * Path part for operation getRecordOutline
   */
  static readonly GetRecordOutlinePath =
    "/api/v1/finalDocSets/{recordUid}/outline";

  /**
   * Get the outline of the section headers in the docSet for this abstract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecordOutline()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecordOutline$Response(params: {
    view: "FULL" | "NONE";
  }): Observable<StrictHttpResponse<OApiReqFullDocOutlineViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.GetRecordOutlinePath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiReqFullDocOutlineViewModel>;
        })
      );
  }

  /**
   * Get the outline of the section headers in the docSet for this abstract.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecordOutline$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecordOutline(params: {
    view: "FULL" | "NONE";
  }): Observable<OApiReqFullDocOutlineViewModel> {
    return this.getRecordOutline$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiReqFullDocOutlineViewModel>) =>
          r.body as OApiReqFullDocOutlineViewModel
      )
    );
  }

  /**
   * Path part for operation getRecordTableOfContents
   */
  static readonly GetRecordTableOfContentsPath =
    "/api/v1/finalDocSets/{recordUid}/tableOfContents";

  /**
   * Get the TOC for this abstract including {@link DocOutline} and {@link Bookmark}s, broken down by the record's documents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecordTableOfContents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecordTableOfContents$Response(params: {
    view:
      | "COMPLETE"
      | "EVENTS"
      | "FORM_NAME"
      | "FULL"
      | "MINIMAL"
      | "PROVISION_VALUES"
      | "MULTI_PROVISION_STRINGS"
      | "MULTI_PROVISION_STRINGS_EXPORT"
      | "MULTI_PROVISION_STRINGS_NO_TABLES_EXPORT"
      | "MULTI_PROVISION_STRINGS_ONLY_TABLES_EXPORT"
      | "MULTI_PROVISION_VALUES"
      | "PROVISION_FORM"
      | "RELATED_DOCS"
      | "PERMISSIONS"
      | "TABLE_OF_CONTENTS"
      | "NONE";
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullFinalAbstractViewModel
      | OApiRespFormNameAbstractViewModel
      | OApiRespMultiProvisionStringsAbstractViewModel
      | OApiRespMultiProvisionStringsExportAbstractViewModel
      | OApiRespFinalAbstractViewCompleteModel
      | OApiRespEventsAbstractViewModel
      | OApiRespRelatedDocsAbstractViewModel
      | OApiRespPermissionsAbstractViewV2Model
      | OApiRespMinimalFinalAbstractViewModel
      | OApiRespProvisionValuesAbstractViewModel
      | OApiRespTableOfContentsViewModel
      | OApiRespMultiProvisionValuesAbstractViewModel
      | OApiRespProvisionFormAbstractViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.GetRecordTableOfContentsPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >;
        })
      );
  }

  /**
   * Get the TOC for this abstract including {@link DocOutline} and {@link Bookmark}s, broken down by the record's documents.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecordTableOfContents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecordTableOfContents(params: {
    view:
      | "COMPLETE"
      | "EVENTS"
      | "FORM_NAME"
      | "FULL"
      | "MINIMAL"
      | "PROVISION_VALUES"
      | "MULTI_PROVISION_STRINGS"
      | "MULTI_PROVISION_STRINGS_EXPORT"
      | "MULTI_PROVISION_STRINGS_NO_TABLES_EXPORT"
      | "MULTI_PROVISION_STRINGS_ONLY_TABLES_EXPORT"
      | "MULTI_PROVISION_VALUES"
      | "PROVISION_FORM"
      | "RELATED_DOCS"
      | "PERMISSIONS"
      | "TABLE_OF_CONTENTS"
      | "NONE";
  }): Observable<
    | OApiRespFullFinalAbstractViewModel
    | OApiRespFormNameAbstractViewModel
    | OApiRespMultiProvisionStringsAbstractViewModel
    | OApiRespMultiProvisionStringsExportAbstractViewModel
    | OApiRespFinalAbstractViewCompleteModel
    | OApiRespEventsAbstractViewModel
    | OApiRespRelatedDocsAbstractViewModel
    | OApiRespPermissionsAbstractViewV2Model
    | OApiRespMinimalFinalAbstractViewModel
    | OApiRespProvisionValuesAbstractViewModel
    | OApiRespTableOfContentsViewModel
    | OApiRespMultiProvisionValuesAbstractViewModel
    | OApiRespProvisionFormAbstractViewModel
  > {
    return this.getRecordTableOfContents$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >
        ) =>
          r.body as
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
      )
    );
  }

  /**
   * Path part for operation listProvisionsForAbstract
   */
  static readonly ListProvisionsForAbstractPath =
    "/api/v1/finalDocSets/{recordUid}/provisions";

  /**
   * List the provisions for this abstract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listProvisionsForAbstract()` instead.
   *
   * This method doesn't expect any request body.
   */
  listProvisionsForAbstract$Response(params: {
    view: "MINIMAL" | "FULL" | "EMBED" | "NONE";
  }): Observable<StrictHttpResponse<OApiRespMultiProvisionCategoryModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.ListProvisionsForAbstractPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiProvisionCategoryModel>;
        })
      );
  }

  /**
   * List the provisions for this abstract.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listProvisionsForAbstract$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listProvisionsForAbstract(params: {
    view: "MINIMAL" | "FULL" | "EMBED" | "NONE";
  }): Observable<OApiRespMultiProvisionCategoryModel> {
    return this.listProvisionsForAbstract$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiProvisionCategoryModel>) =>
          r.body as OApiRespMultiProvisionCategoryModel
      )
    );
  }

  /**
   * Path part for operation listRecords
   */
  static readonly ListRecordsPath = "/api/v1/finalDocSets";

  /**
   * List of records that user has access to.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRecords$Response(params: {
    view:
      | "COMPLETE"
      | "EVENTS"
      | "FORM_NAME"
      | "FULL"
      | "MINIMAL"
      | "PROVISION_VALUES"
      | "MULTI_PROVISION_STRINGS"
      | "MULTI_PROVISION_STRINGS_EXPORT"
      | "MULTI_PROVISION_STRINGS_NO_TABLES_EXPORT"
      | "MULTI_PROVISION_STRINGS_ONLY_TABLES_EXPORT"
      | "MULTI_PROVISION_VALUES"
      | "PROVISION_FORM"
      | "RELATED_DOCS"
      | "PERMISSIONS"
      | "TABLE_OF_CONTENTS"
      | "NONE";
  }): Observable<
    StrictHttpResponse<
      | OApiRespMultiFullFinalAbstractViewModel
      | OApiRespMultiFormNameAbstractViewModel
      | OApiRespMultiMultiProvisionStringsAbstractViewModel
      | OApiRespMultiMultiProvisionStringsExportAbstractViewModel
      | OApiRespMultiFinalAbstractViewCompleteModel
      | OApiRespMultiEventsAbstractViewModel
      | OApiRespMultiRelatedDocsAbstractViewModel
      | OApiRespMultiPermissionsAbstractViewV2Model
      | OApiRespMultiMinimalFinalAbstractViewModel
      | OApiRespMultiProvisionValuesAbstractViewModel
      | OApiRespMultiTableOfContentsViewModel
      | OApiRespMultiMultiProvisionValuesAbstractViewModel
      | OApiRespMultiProvisionFormAbstractViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.ListRecordsPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMultiFullFinalAbstractViewModel
            | OApiRespMultiFormNameAbstractViewModel
            | OApiRespMultiMultiProvisionStringsAbstractViewModel
            | OApiRespMultiMultiProvisionStringsExportAbstractViewModel
            | OApiRespMultiFinalAbstractViewCompleteModel
            | OApiRespMultiEventsAbstractViewModel
            | OApiRespMultiRelatedDocsAbstractViewModel
            | OApiRespMultiPermissionsAbstractViewV2Model
            | OApiRespMultiMinimalFinalAbstractViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespMultiTableOfContentsViewModel
            | OApiRespMultiMultiProvisionValuesAbstractViewModel
            | OApiRespMultiProvisionFormAbstractViewModel
          >;
        })
      );
  }

  /**
   * List of records that user has access to.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRecords(params: {
    view:
      | "COMPLETE"
      | "EVENTS"
      | "FORM_NAME"
      | "FULL"
      | "MINIMAL"
      | "PROVISION_VALUES"
      | "MULTI_PROVISION_STRINGS"
      | "MULTI_PROVISION_STRINGS_EXPORT"
      | "MULTI_PROVISION_STRINGS_NO_TABLES_EXPORT"
      | "MULTI_PROVISION_STRINGS_ONLY_TABLES_EXPORT"
      | "MULTI_PROVISION_VALUES"
      | "PROVISION_FORM"
      | "RELATED_DOCS"
      | "PERMISSIONS"
      | "TABLE_OF_CONTENTS"
      | "NONE";
  }): Observable<
    | OApiRespMultiFullFinalAbstractViewModel
    | OApiRespMultiFormNameAbstractViewModel
    | OApiRespMultiMultiProvisionStringsAbstractViewModel
    | OApiRespMultiMultiProvisionStringsExportAbstractViewModel
    | OApiRespMultiFinalAbstractViewCompleteModel
    | OApiRespMultiEventsAbstractViewModel
    | OApiRespMultiRelatedDocsAbstractViewModel
    | OApiRespMultiPermissionsAbstractViewV2Model
    | OApiRespMultiMinimalFinalAbstractViewModel
    | OApiRespMultiProvisionValuesAbstractViewModel
    | OApiRespMultiTableOfContentsViewModel
    | OApiRespMultiMultiProvisionValuesAbstractViewModel
    | OApiRespMultiProvisionFormAbstractViewModel
  > {
    return this.listRecords$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMultiFullFinalAbstractViewModel
            | OApiRespMultiFormNameAbstractViewModel
            | OApiRespMultiMultiProvisionStringsAbstractViewModel
            | OApiRespMultiMultiProvisionStringsExportAbstractViewModel
            | OApiRespMultiFinalAbstractViewCompleteModel
            | OApiRespMultiEventsAbstractViewModel
            | OApiRespMultiRelatedDocsAbstractViewModel
            | OApiRespMultiPermissionsAbstractViewV2Model
            | OApiRespMultiMinimalFinalAbstractViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespMultiTableOfContentsViewModel
            | OApiRespMultiMultiProvisionValuesAbstractViewModel
            | OApiRespMultiProvisionFormAbstractViewModel
          >
        ) =>
          r.body as
            | OApiRespMultiFullFinalAbstractViewModel
            | OApiRespMultiFormNameAbstractViewModel
            | OApiRespMultiMultiProvisionStringsAbstractViewModel
            | OApiRespMultiMultiProvisionStringsExportAbstractViewModel
            | OApiRespMultiFinalAbstractViewCompleteModel
            | OApiRespMultiEventsAbstractViewModel
            | OApiRespMultiRelatedDocsAbstractViewModel
            | OApiRespMultiPermissionsAbstractViewV2Model
            | OApiRespMultiMinimalFinalAbstractViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespMultiTableOfContentsViewModel
            | OApiRespMultiMultiProvisionValuesAbstractViewModel
            | OApiRespMultiProvisionFormAbstractViewModel
      )
    );
  }

  /**
   * Path part for operation followRecord
   */
  static readonly FollowRecordPath = "/api/v1/finalDocSets/{recordUid}/follow";

  /**
   * Follow a record, to get notification when there are activities on the record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followRecord()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  followRecord$Response(params?: {
    body?: RequestModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.FollowRecordPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Follow a record, to get notification when there are activities on the record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followRecord$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  followRecord(params?: { body?: RequestModel }): Observable<void> {
    return this.followRecord$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation unFollowRecord
   */
  static readonly UnFollowRecordPath =
    "/api/v1/finalDocSets/{recordUid}/follow";

  /**
   * Un-follow a record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unFollowRecord()` instead.
   *
   * This method doesn't expect any request body.
   */
  unFollowRecord$Response(params?: {}): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.UnFollowRecordPath,
      "delete"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Un-follow a record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unFollowRecord$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unFollowRecord(params?: {}): Observable<void> {
    return this.unFollowRecord$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation searchRecords
   */
  static readonly SearchRecordsPath = "/api/v2/search/finalDocSets";

  /**
   * Search the abstracts for this user / account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchRecords()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  searchRecords$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespMultiFullFinalAbstractViewModel
      | OApiRespMultiFormNameAbstractViewModel
      | OApiRespMultiMultiProvisionStringsAbstractViewModel
      | OApiRespMultiMultiProvisionStringsExportAbstractViewModel
      | OApiRespMultiFinalAbstractViewCompleteModel
      | OApiRespMultiEventsAbstractViewModel
      | OApiRespMultiRelatedDocsAbstractViewModel
      | OApiRespMultiPermissionsAbstractViewV2Model
      | OApiRespMultiMinimalFinalAbstractViewModel
      | OApiRespMultiProvisionValuesAbstractViewModel
      | OApiRespMultiTableOfContentsViewModel
      | OApiRespMultiMultiProvisionValuesAbstractViewModel
      | OApiRespMultiProvisionFormAbstractViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.SearchRecordsPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMultiFullFinalAbstractViewModel
            | OApiRespMultiFormNameAbstractViewModel
            | OApiRespMultiMultiProvisionStringsAbstractViewModel
            | OApiRespMultiMultiProvisionStringsExportAbstractViewModel
            | OApiRespMultiFinalAbstractViewCompleteModel
            | OApiRespMultiEventsAbstractViewModel
            | OApiRespMultiRelatedDocsAbstractViewModel
            | OApiRespMultiPermissionsAbstractViewV2Model
            | OApiRespMultiMinimalFinalAbstractViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespMultiTableOfContentsViewModel
            | OApiRespMultiMultiProvisionValuesAbstractViewModel
            | OApiRespMultiProvisionFormAbstractViewModel
          >;
        })
      );
  }

  /**
   * Search the abstracts for this user / account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchRecords$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  searchRecords(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespMultiFullFinalAbstractViewModel
    | OApiRespMultiFormNameAbstractViewModel
    | OApiRespMultiMultiProvisionStringsAbstractViewModel
    | OApiRespMultiMultiProvisionStringsExportAbstractViewModel
    | OApiRespMultiFinalAbstractViewCompleteModel
    | OApiRespMultiEventsAbstractViewModel
    | OApiRespMultiRelatedDocsAbstractViewModel
    | OApiRespMultiPermissionsAbstractViewV2Model
    | OApiRespMultiMinimalFinalAbstractViewModel
    | OApiRespMultiProvisionValuesAbstractViewModel
    | OApiRespMultiTableOfContentsViewModel
    | OApiRespMultiMultiProvisionValuesAbstractViewModel
    | OApiRespMultiProvisionFormAbstractViewModel
  > {
    return this.searchRecords$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMultiFullFinalAbstractViewModel
            | OApiRespMultiFormNameAbstractViewModel
            | OApiRespMultiMultiProvisionStringsAbstractViewModel
            | OApiRespMultiMultiProvisionStringsExportAbstractViewModel
            | OApiRespMultiFinalAbstractViewCompleteModel
            | OApiRespMultiEventsAbstractViewModel
            | OApiRespMultiRelatedDocsAbstractViewModel
            | OApiRespMultiPermissionsAbstractViewV2Model
            | OApiRespMultiMinimalFinalAbstractViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespMultiTableOfContentsViewModel
            | OApiRespMultiMultiProvisionValuesAbstractViewModel
            | OApiRespMultiProvisionFormAbstractViewModel
          >
        ) =>
          r.body as
            | OApiRespMultiFullFinalAbstractViewModel
            | OApiRespMultiFormNameAbstractViewModel
            | OApiRespMultiMultiProvisionStringsAbstractViewModel
            | OApiRespMultiMultiProvisionStringsExportAbstractViewModel
            | OApiRespMultiFinalAbstractViewCompleteModel
            | OApiRespMultiEventsAbstractViewModel
            | OApiRespMultiRelatedDocsAbstractViewModel
            | OApiRespMultiPermissionsAbstractViewV2Model
            | OApiRespMultiMinimalFinalAbstractViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespMultiTableOfContentsViewModel
            | OApiRespMultiMultiProvisionValuesAbstractViewModel
            | OApiRespMultiProvisionFormAbstractViewModel
      )
    );
  }

  /**
   * Path part for operation searchTableRecords
   */
  static readonly SearchTableRecordsPath = "/api/v2/searchTable/finalDocSets";

  /**
   * Search the abstracts for this user / account, returning results in tabular format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTableRecords()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  searchTableRecords$Response(params?: {
    body?: RequestModel;
  }): Observable<StrictHttpResponse<OApiRespMultiSearchTableAbstractsModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.SearchTableRecordsPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiSearchTableAbstractsModel>;
        })
      );
  }

  /**
   * Search the abstracts for this user / account, returning results in tabular format.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchTableRecords$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  searchTableRecords(params?: {
    body?: RequestModel;
  }): Observable<OApiRespMultiSearchTableAbstractsModel> {
    return this.searchTableRecords$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiSearchTableAbstractsModel>) =>
          r.body as OApiRespMultiSearchTableAbstractsModel
      )
    );
  }

  /**
   * Path part for operation removeShared4
   */
  static readonly RemoveShared4Path =
    "/api/v1/finalDocSets/{recordUid}/removeShared";

  /**
   * Un-share from a record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeShared4()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeShared4$Response(params: {
    body: OApiReqRemoveSharedFileRequestModel;
  }): Observable<StrictHttpResponse<OApiRespRemoveSharedModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.RemoveShared4Path,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespRemoveSharedModel>;
        })
      );
  }

  /**
   * Un-share from a record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeShared4$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeShared4(params: {
    body: OApiReqRemoveSharedFileRequestModel;
  }): Observable<OApiRespRemoveSharedModel> {
    return this.removeShared4$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespRemoveSharedModel>) =>
          r.body as OApiRespRemoveSharedModel
      )
    );
  }

  /**
   * Path part for operation share4
   */
  static readonly Share4Path = "/api/v1/finalDocSets/{recordUid}/share";

  /**
   * Share a record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `share4()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  share4$Response(params: {
    body: OApiReqShareEntityRequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.Share4Path,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Share a record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `share4$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  share4(params: {
    body: OApiReqShareEntityRequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.share4$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation shareBatch
   */
  static readonly ShareBatchPath = "/api/v1/finalDocSets/share";

  /**
   * Share list of records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shareBatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shareBatch$Response(params: {
    body: OApiReqShareMultiEntitiesRequestModel;
  }): Observable<StrictHttpResponse<OApiRespSharedModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecordService.ShareBatchPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespSharedModel>;
        })
      );
  }

  /**
   * Share list of records.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `shareBatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shareBatch(params: {
    body: OApiReqShareMultiEntitiesRequestModel;
  }): Observable<OApiRespSharedModel> {
    return this.shareBatch$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespSharedModel>) =>
          r.body as OApiRespSharedModel
      )
    );
  }
}
