import { Component, Input, ViewEncapsulation } from "@angular/core";
import {
  AppAuthorities,
  NAVIGATION,
  QA_NAVIGATION,
} from "@@intelease/web/intelease/constants";
import { environment } from "apps/ui/src/environments/environment";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "itls-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ItlsNavigationComponent {
  @Input()
  layout = "vertical";

  @Input()
  navigation: any = environment.testbed ? QA_NAVIGATION : NAVIGATION;

  public static determineExpectedAuthorities(id: string): string[] {
    if (id === "newDashboard") {
      return [AppAuthorities.READ_DASHBOARD];
    }
    if (id === "projectsGroup") {
      return [AppAuthorities.READ_PROJECT];
    }
    if (id === "myForms") {
      return [AppAuthorities.READ_PROVISION_FORM];
    }
    if (id === "recordsGroup") {
      return [AppAuthorities.READ_RECORD];
    }
    if (id === "documentSetGroup") {
      return [AppAuthorities.READ_RECORD];
    }
    if (id === "tasks") {
      return [AppAuthorities.READ_TASK];
    }
    if (id === "queues") {
      return [AppAuthorities.READ_QUEUE];
    }
    return [];
  }

  public static determineIconExpectedAuthorities(id: string): string[] {
    if (id === "newDashboard") {
      return [AppAuthorities.CREATE_DASHBOARD];
    }
    if (id === "projectsGroup") {
      return [AppAuthorities.CREATE_PROJECT];
    }
    if (id === "myForms") {
      return [AppAuthorities.CREATE_PROVISION_FORM];
    }
    if (id === "recordsGroup") {
      return [AppAuthorities.CREATE_RECORD];
    }
    if (id === "documentSetGroup") {
      return [AppAuthorities.CREATE_RECORD];
    }
    if (id === "tasks") {
      return [AppAuthorities.CREATE_TASK];
    }
    if (id === "queues") {
      return [AppAuthorities.CREATE_QUEUE];
    }
    return [];
  }
}
