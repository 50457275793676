import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { TbNavigationModule } from "../../../navigation/navigation.module";
import { NavbarVerticalStyle1Component } from "./style-1.component";
import { CommonDirectivesModule } from "@@intelease/web/common/directives";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CommonModule } from "@angular/common";
import { WebNotificationsModule } from "@@intelease/web/notifications";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { SearchModule } from "@@intelease/web/intelease/modal";
import { AddProvisionFormModalModule } from "@@intelease/web/forms/src/lib/modals/add-provision-form/add-provision-form-modal.module";
import { AddProjectModule } from "@@intelease/web/projects/src/lib/modals/add-project";
import { MatBadgeModule } from "@angular/material/badge";
import { AppStateNotificationsModule } from "@@intelease/app-state/notifications";
import { MatDividerModule } from "@angular/material/divider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ItlsNewUploadModule } from "@@intelease/web/ui/src/lib/itls-new-upload";
import { RouterModule } from "@angular/router";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";

const MATERIAL_MODULES = [
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatDialogModule,
  MatBadgeModule,
  MatDividerModule,
  MatTooltipModule,
];

@NgModule({
  declarations: [NavbarVerticalStyle1Component],
  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    CommonDirectivesModule,
    FlexLayoutModule,
    TbNavigationModule,
    WebNotificationsModule,
    SearchModule,
    AddProvisionFormModalModule,
    AddProjectModule,
    AppStateNotificationsModule,
    ItlsNewUploadModule,
    RouterModule,
    InteleaseCommonModule,
    ItlsCustomIconModule,
  ],
  exports: [NavbarVerticalStyle1Component],
})
export class NavbarVerticalStyle1Module {}
