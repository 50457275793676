import {
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
  Pipe,
  PipeTransform,
} from "@angular/core";
import { Subject } from "rxjs";
import { isEmpty } from "lodash";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FilteredProvisionForm } from "@@intelease/web/ui/src/lib/itls-new-upload";
import {
  QueueService,
  SimpleQueueDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { QueueFacade } from "@@intelease/app-state/queue/src";
import { UserInfoService } from "@@intelease/web/intelease/services";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

type QueueSharePermissionType = "PRIVATE" | "PUBLIC" | "VIEWABLE";

@Component({
  selector: "intelease-add-queue-modal",
  templateUrl: "./add-queue-modal.component.html",
  styleUrls: [],
})
export class AddQueueModalComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload: any;

  onlyMyQueues: SimpleQueueDtoModel[];
  name = "";
  description = "";
  queuePermission: QueueSharePermissionType = "PRIVATE";

  provisionFormUid = "";

  queuePermissionOptions: {
    name: string;
    value: QueueSharePermissionType;
  }[] = [
    {
      name: "Private to me",
      value: "PRIVATE",
    },
    {
      name: "Others can see it",
      value: "VIEWABLE",
    },
    {
      name: "Others can see and use it",
      value: "PUBLIC",
    },
  ];

  areFieldsValid = false;

  public CreateButtonPipeTypesEnum = CreateButtonPipeTypesEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<AddQueueModalComponent>,
    private inputValidationService: InputValidationService,
    private queueService: QueueService,
    private queueFacade: QueueFacade,
    private notificationService: MessageService
  ) {}

  ngOnInit() {
    this.payload = this.dialogData.payload;
    this.mode = this.dialogData.mode;
    this.queueFacade.usableQueueListLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((usableQueues) => {
        const currentUserUid = UserInfoService.getUserUid();
        this.onlyMyQueues = usableQueues.filter((queue) => {
          return queue?.createdBy?.uid === currentUserUid;
        });
      });
    this.queueFacade.loadUsableQueueList();
  }

  onSaveClick() {
    const name = this.inputValidationService.sanitizeText(this.name);
    const desc = this.inputValidationService.sanitizeText(this.description);

    if (!this.validateFields(name, this.provisionFormUid)) {
      return;
    }

    this.queueService
      .create1({
        body: {
          data: {
            name: this.name,
            description: this.description,
            processingConfig: {
              provisionFormUid: this.provisionFormUid,
            },
            permissionCategory: this.queuePermission,
          },
        },
      })
      .subscribe((response) => {
        if (response.data?.uid) {
          this.modalRes.data = {
            name: name,
            description: desc,
            uid: response.data?.uid,
          };

          this.dialogRef.close({
            ...this.modalRes,
            type: ModalsResponseTypeEnum.CLOSE,
          });
        } else {
          this.dialogRef.close({
            ...this.modalRes,
            type: ModalsResponseTypeEnum.DISMISS,
          });
        }
      });
  }

  private validateFields(name: string, provisionFormUid: string): boolean {
    if (
      !this.inputValidationService.usefulTextElseAlert(
        name,
        "You must fill in a name!"
      )
    ) {
      return false;
    }
    const matchingQueue = this.onlyMyQueues.filter((queue) => {
      if (queue.name.toLocaleLowerCase() === name.trim().toLocaleLowerCase()) {
        return true;
      }
      return false;
    });
    if (matchingQueue.length) {
      this.notificationService.warning(
        "Queue name shouldn't match with an existing active owned queue name"
      );
      return false;
    }
    if (
      !this.inputValidationService.usefulTextElseAlert(
        provisionFormUid,
        "You must select a provision form!"
      )
    ) {
      return false;
    }
    return true;
  }

  onQueuePermissionChange(evt: any) {
    this.queuePermission = evt.value as QueueSharePermissionType;
    this.checkValidateFields();
  }

  onProvisionFormSelection(evt: FilteredProvisionForm) {
    this.provisionFormUid = evt.uid;
    this.checkValidateFields();
  }

  checkValidateFields() {
    if (this.name === "") {
      this.areFieldsValid = false;
    } else if (
      this.provisionFormUid === undefined ||
      this.provisionFormUid === null ||
      this.provisionFormUid === ""
    ) {
      this.areFieldsValid = false;
    } else {
      this.areFieldsValid = true;
    }
  }

  onDismissModalClick() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.DISMISS,
    });
  }
}

export enum CreateButtonPipeTypesEnum {
  CreateButtonTooltip = "Create Button Tooltip",
  CreateButtonIsDisabled = "Create Button Is Disabled",
  CreateDuplicateButtonTooltip = "Create Duplicate Button Tooltip",
  CreateDuplicateButtonIsDisabled = "Create Duplicate Button Is Disabled",
}

@Pipe({ name: "createButtonPipe", pure: true })
export class CreateButtonPipe implements PipeTransform {
  transform(
    type: CreateButtonPipeTypesEnum,
    onlyMyQueues: SimpleQueueDtoModel[],
    queueName: string,
    provisionFormUid?: string
  ): any {
    const matchingQueue = onlyMyQueues?.filter((queue) => {
      if (
        queue.name.toLocaleLowerCase() === queueName.trim().toLocaleLowerCase()
      ) {
        return true;
      }
      return false;
    });

    switch (type) {
      case CreateButtonPipeTypesEnum.CreateButtonTooltip: {
        if (matchingQueue?.length) {
          return "Queue name duplicated, please change.";
        } else if (isEmpty(queueName?.trim())) {
          return "The queue name is required!";
        } else if (isEmpty(provisionFormUid)) {
          return "The provision form is required!";
        }
        return "";
      }
      case CreateButtonPipeTypesEnum.CreateButtonIsDisabled: {
        if (matchingQueue) {
          return (
            matchingQueue?.length ||
            isEmpty(queueName) ||
            isEmpty(provisionFormUid)
          );
        }
        return true;
      }
      case CreateButtonPipeTypesEnum.CreateDuplicateButtonTooltip: {
        if (matchingQueue?.length) {
          return "Queue name duplicated, please change.";
        } else if (isEmpty(queueName?.trim())) {
          return "The queue name is required!";
        }
        return "";
      }
      case CreateButtonPipeTypesEnum.CreateDuplicateButtonIsDisabled: {
        if (matchingQueue) {
          return matchingQueue?.length || isEmpty(queueName);
        }
        return true;
      }
    }
  }
}
