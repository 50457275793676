import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { fetch, pessimisticUpdate } from "@ngrx/router-store/data-persistence";
import { DatePipe } from "@angular/common";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { cloneDeep } from "lodash";

import { DrivePartialState } from "./drive.reducer";
import {
  DriveActionTypes,
  UpdateRecord,
  UpdateRecordFailed,
  LoadAllDriveNodes,
  LoadAllDriveNodesSucceeded,
  LoadAllDriveNodesFailed,
  AddRecord,
  AddRecordSucceeded,
  AddRecordFailed,
  AddDirectory,
  AddDirectorySucceeded,
  AddDirectoryFailed,
  ChangePage,
  ChangePageSucceeded,
  ChangePageError,
  SortDriveNodes,
  SortsRecordsError,
  SortsRecordsSucceeded,
  RenameDriveNodeSucceeded,
  RenameDriveNodeError,
  RenameDriveNode,
  AddDocSet,
  AddDocSetSucceeded,
  AddDocSetFailed,
  UpdateCustomTagsFailed,
  UpdateCustomTags,
  UpdateCustomTagsSucceeded,
  AddBatchDocSetsToQueue,
  AddBatchDocSetToQueueFailed,
  AddBatchDocSetToQueueSuccessful,
} from "./drive.actions";

import {
  DriveService,
  FolderStructureNodeDtoModel,
  MediumNodeDtoModel,
  OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel,
  QueueService,
  RecordService,
} from "@@intelease/api-models/adex-api-model-src";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";
import { DriveNodeDtoModel } from "../models/drive-node-dto-model";
import { Permission, DriveNodeType } from "../models/drive-node.types";
import { LocalStorageKey } from "@@intelease/web/common/enums/local-storage.keys";
import { Router } from "@angular/router";
import { OwnerModel } from "@@intelease/web/common/models";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";
import { DriveFacade } from "./drive.facade";

@Injectable()
export class DriveEffects {
  updateRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriveActionTypes.UpdateRecord),
      pessimisticUpdate({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        run: (action: UpdateRecord, state: DrivePartialState) => {
          // this.recordService.getRecord({})
        },
        onError(action: UpdateRecord, error) {
          return new UpdateRecordFailed(error);
        },
      })
    )
  );

  addRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriveActionTypes.AddRecord),
      pessimisticUpdate({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        run: (action: AddRecord, state: DrivePartialState) => {
          const { category, data, recordUid, directoryUid } = action.payload;
          const name = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_FULLNAME)
          );
          const email = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_EMAIL)
          );
          const userUid = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_UID)
          );
          const currentDate = this.convertDateToTimeZone(
            "America/Los_Angeles",
            new Date(),
            -7
          );
          const createdAt = data?.createdAt
            ? new Date(
                (data?.createdAt.getTimezoneOffset() - 420) * 60000 +
                  Date.parse(`${data?.createdAt}`)
              )
            : currentDate;
          const lastModifiedDate = data?.lastModifiedDate
            ? new Date(
                (data?.lastModifiedDate.getTimezoneOffset() - 420) * 60000 +
                  Date.parse(`${data?.lastModifiedDate}`)
              )
            : currentDate;
          return new AddRecordSucceeded({
            category: category,
            directoryUid: directoryUid,
            data: Json2TypescriptHelper.convertToEntity(
              {
                createdAt,
                lastModifiedDate,
                name: data?.name,
                owner: {
                  name: data?.owner?.name || name,
                  email: data?.owner?.email || email,
                  uid: data?.owner?.uid || userUid,
                },
                permissions: data?.permissions || [
                  Permission.READ,
                  Permission.MOVE,
                  Permission.COMMENT,
                  Permission.EXPORT,
                  Permission.DELETE,
                  Permission.EDIT,
                  Permission.SHARE,
                ],
                shared: data?.shared || false,
                type: data?.type || DriveNodeType.DOC_SET,
                uid: recordUid,
              },
              DriveNodeDtoModel
            ),
          });
        },
        onError(action: AddRecord, error) {
          return new AddRecordFailed(error);
        },
      })
    )
  );

  addDocSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriveActionTypes.AddDocSet),
      pessimisticUpdate({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        run: (action: AddDocSet, state: DrivePartialState) => {
          const { category, data, docSetUid, directoryUid } = action.payload;
          const name = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_FULLNAME)
          );
          const email = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_EMAIL)
          );
          const userUid = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_UID)
          );
          const currentDate = this.convertDateToTimeZone(
            "America/Los_Angeles",
            new Date(),
            -7
          );
          const createdAt = data?.createdAt
            ? new Date(
                (data?.createdAt.getTimezoneOffset() - 420) * 60000 +
                  Date.parse(`${data?.createdAt}`)
              )
            : currentDate;
          const lastModifiedDate = data?.lastModifiedDate
            ? new Date(
                (data?.lastModifiedDate.getTimezoneOffset() - 420) * 60000 +
                  Date.parse(`${data?.lastModifiedDate}`)
              )
            : currentDate;
          return new AddDocSetSucceeded({
            category: category,
            directoryUid: directoryUid,
            data: Json2TypescriptHelper.convertToEntity(
              {
                createdAt,
                lastModifiedDate,
                name: data?.name,
                owner: {
                  name: data?.owner?.name || name,
                  email: data?.owner?.email || email,
                  uid: data?.owner?.uid || userUid,
                },
                permissions: data?.permissions || [
                  Permission.READ,
                  Permission.MOVE,
                  Permission.COMMENT,
                  Permission.EXPORT,
                  Permission.DELETE,
                  Permission.EDIT,
                  Permission.SHARE,
                ],
                shared: data?.shared || false,
                type: data?.type || DriveNodeType.DOC_SET,
                uid: docSetUid,
              },
              DriveNodeDtoModel
            ),
          });
        },
        onError(action: AddDocSet, error) {
          return new AddDocSetFailed(error);
        },
      })
    )
  );

  addDirectory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriveActionTypes.AddDirectory),
      pessimisticUpdate({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        run: (action: AddDirectory, state: DrivePartialState) => {
          const { category, data, directoryUid } = action.payload;
          const dateNow = new Date(
            (new Date().getTimezoneOffset() - 420) * 60000 + Date.now()
          );
          const name = cloneDeep(localStorage.getItem(LocalStorageKey.NAME));
          const email = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_EMAIL)
          );
          const userUid = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_UID)
          );
          const currentDate = `${this.datePipe.transform(
            dateNow,
            "YYYY-MM-ddThh:mm:ss.s-07:00"
          )}[America/Los_Angeles]`;
          const createdAt = data?.createdAt
            ? new Date(
                (data?.createdAt.getTimezoneOffset() - 420) * 60000 +
                  Date.parse(`${data?.createdAt}`)
              )
            : currentDate;
          const lastModifiedDate = data?.lastModifiedDate
            ? new Date(
                (data?.lastModifiedDate.getTimezoneOffset() - 420) * 60000 +
                  Date.parse(`${data?.lastModifiedDate}`)
              )
            : currentDate;

          const payload = {
            category: category,
            directoryUid: directoryUid,
            data: Json2TypescriptHelper.convertToEntity(
              {
                createdAt,
                lastModifiedDate,
                name: data?.name,
                owner: {
                  name: data?.owner?.name || name,
                  email: data?.owner?.email || email,
                  uid: data?.owner?.uid || userUid,
                },
                permissions: data?.permissions || [
                  "READ",
                  "MOVE",
                  "COMMENT",
                  "EXPORT",
                  "DELETE",
                  "EDIT",
                  "SHARE",
                ],
                shared: data?.shared || false,
                type: data?.type || DriveNodeType.DIRECTORY,
                uid: data?.uid,
              },
              FolderStructureNodeDtoModel
            ),
          };

          this.driveFacade.newFolderCreated$.next({
            node: {
              createdAt: null as any,
              lastModifiedDate: payload.data.lastModifiedDate as Date,
              name: payload.data.name as string,
              owner: payload.data.owner as OwnerModel,
              permissions: payload.data.permissions as (
                | "READ"
                | "EDIT"
                | "DELETE"
                | "EXPORT"
                | "MOVE"
                | "COMMENT"
                | "SHARE"
              )[],
              shared: false,
              size: 0,
              type: DriveNodeType.DIRECTORY,
              uid: payload.data.uid as string,
            },
            parentDirectoryUid: directoryUid as string,
            refreshDirectories: false,
          });

          return new AddDirectorySucceeded(payload);
        },
        onError(action: AddDirectory, error) {
          return new AddDirectoryFailed(error);
        },
      })
    )
  );

  changePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriveActionTypes.ChangePage),
      pessimisticUpdate({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        run: (action: ChangePage, state: DrivePartialState) => {
          return new ChangePageSucceeded(action.payload);
        },
        onError: (action: ChangePage, error) => {
          return new ChangePageError(error);
        },
      })
    )
  );

  loadAllDriveNodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriveActionTypes.LoadAllDriveNodes),
      switchMap((action: LoadAllDriveNodes) => {
        const { directoryUid, category } = action.payload;
        if (category) {
          return this.driveService.getRootDirectories(action.payload).pipe(
            map(
              (res) =>
                new LoadAllDriveNodesSucceeded(
                  Json2TypescriptHelper.convertToEntity(
                    res,
                    OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
                  ).data
                )
            ),
            catchError((err) => {
              this.router.navigateByUrl("/dashboard");
              return of(new LoadAllDriveNodesFailed(err));
            })
          );
        } else {
          return this.driveService
            .getChildren({
              ...action.payload,
              directoryUid: directoryUid as string,
            })
            .pipe(
              map(
                (res) =>
                  new LoadAllDriveNodesSucceeded(
                    Json2TypescriptHelper.convertToEntity(
                      res,
                      OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
                    ).data
                  )
              ),
              catchError((err) => {
                this.router.navigateByUrl("/dashboards");
                return of(new LoadAllDriveNodesFailed(err));
              })
            );
        }
      })
    )
  );

  sortsDriveNodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriveActionTypes.SortDriveNodes),
      switchMap((action: SortDriveNodes) => {
        const { category, directoryUid, sorts, page, perPage } = action.payload;
        if (category) {
          return this.driveService
            .getRootDirectories({
              category,
              page,
              perPage,
              sorts,
            })
            .pipe(
              map((res) => {
                return new SortsRecordsSucceeded({
                  items: Json2TypescriptHelper.convertToEntity(
                    res,
                    OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
                  ).data?.items,
                });
              }),
              catchError(() => of(new SortsRecordsError()))
            );
        } else {
          return this.driveService
            .getChildren({
              directoryUid: directoryUid as string,
              page,
              perPage,
              sorts,
            })
            .pipe(
              map((res) => {
                return new SortsRecordsSucceeded({
                  items: Json2TypescriptHelper.convertToEntity(
                    res,
                    OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
                  ).data?.items,
                });
              }),
              catchError(() => of(new SortsRecordsError()))
            );
        }
      })
    )
  );

  renameDriveNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriveActionTypes.RenameDriveNode),
      fetch({
        run: (action: RenameDriveNode) => {
          const {
            newName,
            row: { type, uid, name },
          } = action.payload;

          return this.driveService
            .rename({
              nodeUid: uid,
              body: {
                data: {
                  name: newName,
                  type: type as any,
                },
              },
            })
            .pipe(
              map((resp) => {
                return new RenameDriveNodeSucceeded(
                  Json2TypescriptHelper.convertToEntity(
                    resp.data,
                    MediumNodeDtoModel
                  )
                );
              }),
              catchError(() =>
                of(
                  new RenameDriveNodeError({
                    oldName: name,
                    uid,
                  })
                )
              )
            );
        },
      })
    )
  );

  updateCustomTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriveActionTypes.UpdateCustomTags),
      fetch({
        run: (action: UpdateCustomTags) => {
          return this.recordService.editRecordCustomTags(action.payload).pipe(
            map(
              (res) =>
                new UpdateCustomTagsSucceeded({
                  recordUid: action.payload.recordUid,
                  body: {
                    data: {
                      customTags: res.data?.customTags,
                    },
                  },
                })
            )
          );
        },
        onError: () => {
          return new UpdateCustomTagsFailed();
        },
      })
    )
  );

  addDocSetToQueue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriveActionTypes.AddBatchDocSetsToQueue),
      mergeMap((action: AddBatchDocSetsToQueue) => {
        const { docSetUids, queueUid } = action.payload;
        return this.queueService
          .ingestBatchDocSetsIntoQueue({
            queueUid,
            body: {
              data: {
                docSetUids,
              },
            },
          })
          .pipe(
            map((res) => {
              // show the success/error messages on the UI
              if (res.data?.ingestionSuccessCount) {
                this.notificationService.success(
                  `Successfully added ${res.data?.ingestionSuccessCount} out of ${res.data?.ingestionAttemptCount} documents into the queue.`,
                  {
                    Style: "flip",
                    Duration: 5000,
                  }
                );
              }
              if (res.data?.ingestionFailureCount) {
                if (res.data?.errorMessages?.length) {
                  res.data?.errorMessages.forEach((errorMessage) => {
                    this.notificationService.error(errorMessage, {
                      Style: "flip",
                      Duration: 10000,
                    });
                  });
                }
              }
              if (res.data?.ingestionResult) {
                return new AddBatchDocSetToQueueSuccessful({
                  docSetUids,
                  ingestionResult: res.data?.ingestionResult,
                });
              } else {
                return new AddBatchDocSetToQueueFailed(docSetUids);
              }
            }),
            catchError(() => {
              this.notificationService.error(
                `Failed to add documents into the queue.`,
                {
                  Style: "flip",
                  Duration: 5000,
                }
              );
              return of(new AddBatchDocSetToQueueFailed(docSetUids));
            })
          );
      })
    )
  );

  convertDateToTimeZone = (city: string, date: Date, offset: number) => {
    const utc = date.getTime() + date.getTimezoneOffset() * 60000;
    const nd = new Date(utc + 3600000 * offset);
    return `${this.datePipe.transform(
      nd,
      `YYYY-MM-ddThh:mm:ss.s${Math.sign(offset) === -1 ? "-" : "+"}${
        Math.abs(offset) < 10 ? `0${Math.abs(offset)}` : Math.abs(offset)
      }:00`
    )}[${city}]`;
  };

  constructor(
    private actions$: Actions,
    private driveService: DriveService,
    private datePipe: DatePipe,

    // This is probably not a best practice to inject a facade class within the effect class
    private driveFacade: DriveFacade,
    private router: Router,
    private recordService: RecordService,
    private queueService: QueueService,
    private notificationService: MessageService
  ) {}
}
