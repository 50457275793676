/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EntityPermissionAuthoritiesRequestDtoModel")
export class EntityPermissionAuthoritiesRequestDtoModel {
  @JsonProperty("entityType", Any, true)
  entityType?:
    | "FINAL_ABSTRACT"
    | "AUTO_ABSTRACT"
    | "PDF_DOCUMENT_ABSTRACT"
    | "PROVISION_FORM"
    | "PROVISION_LIBRARY"
    | "PROVISION_INFO"
    | "PROJECT"
    | "DASHBOARD"
    | "DIRECTORY"
    | "STACK"
    | "DOC_SET"
    | "QUEUE" = undefined;

  @JsonProperty("entityUid", String, true)
  entityUid?: string = "";

  @JsonProperty("returnEmptyListIfUnauthorized", Boolean, true)
  returnEmptyListIfUnauthorized?: boolean = undefined;
}
