import {
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { delay, filter, take } from "rxjs/operators";
import { InteleasePerfectScrollbarDirective } from "@@intelease/web/common/directives/intelease-perfect-scrollbar/intelease-perfect-scrollbar.directive";
import { ItlsNavigationService } from "@@intelease/web/intelease/components/navigation/navigation.service";
import {
  MainDrawerService,
  UserInfoService,
} from "@@intelease/web/intelease/services";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "@@intelease/app-services/auth";
import { NavigationItemService } from "@@intelease/web/intelease/constants/navigation-item.service";
import { ProvisionFormsModalService } from "@@intelease/web/forms/src/lib/services";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { AddProjectComponent } from "@@intelease/web/projects/src/lib/modals/add-project";
import { ComponentStateHooksInterface } from "@@intelease/app-models/common";
import { NotificationsFacade } from "@@intelease/app-state/notifications";
import {
  CentrifugeService,
  NotificationsService,
} from "@@intelease/app-services/notifications";
import { MatMenuTrigger } from "@angular/material/menu";
import { ModalsResponseTypeEnum } from "@@intelease/web/intelease/enums";
import { UploadModalService } from "@@intelease/web/ui/src/lib/itls-new-upload";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";
import { NotificationTypesEnum } from "@@intelease/app-models/notifications";
import { AppAuthorities } from "@@intelease/web/intelease/constants";
import { QueueModalService } from "@@intelease/web/queues/src/lib/services/queue.modal.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "navbar-vertical-style-2",
  templateUrl: "./style-1.component.html",
  styleUrls: ["./style-1.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component
  implements OnInit, ComponentStateHooksInterface
{
  destroyRef = inject(DestroyRef);
  @ViewChild("notificationMenuTrigger", { static: true })
  notificationMenuTrigger: MatMenuTrigger;
  tbConfig: any;
  navigation: any;

  AUTH = AppAuthorities;

  private _itlsPerfectScrollbar: InteleasePerfectScrollbarDirective;

  isNotificationMenuOpen = false;
  isNotificationMenuInitialized = false;
  userEmail = UserInfoService.getUserEmail();
  userName = UserInfoService.getUserFullName();
  private isLoadedForTheFirstTime = false;

  constructor(
    private messageService: MessageService,
    private _itlsNavigationService: ItlsNavigationService,
    private _router: Router,
    public mainDrawerService: MainDrawerService,
    private dialog: MatDialog,
    private provisionFormsService: ProvisionFormsModalService,
    private queueModalService: QueueModalService,
    private authService: AuthService,
    public notificationsFacade: NotificationsFacade,
    private notificationsService: NotificationsService,
    private uploadModalService: UploadModalService
  ) {
    //
  }

  // Directive
  @ViewChild(InteleasePerfectScrollbarDirective)
  set directive(theDirective: InteleasePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._itlsPerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this._itlsNavigationService.onItemCollapseToggled
      .pipe(delay(500), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this._itlsPerfectScrollbar.update();
      });

    // Scroll to the active item position
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          const activeNavItem: any = document.querySelector(
            "navbar .nav-link.active"
          );

          if (activeNavItem) {
            const activeItemOffsetTop = activeNavItem.offsetTop,
              activeItemOffsetParentTop = activeNavItem.offsetParent.offsetTop,
              scrollDistance =
                activeItemOffsetTop - activeItemOffsetParentTop - 48 * 3;

            this._itlsPerfectScrollbar.scrollToTop(scrollDistance);
          }
        });
      });
  }

  ngOnInit(): void {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((event: any) => {
        const eventModified = event as NavigationEnd;
        if (!this.isLoadedForTheFirstTime) {
          setTimeout(
            () =>
              this.mainDrawerService.checkForSidebarToBeOpened(
                eventModified.urlAfterRedirects
              ),
            1300
          );

          this.isLoadedForTheFirstTime = true;
        }
      });

    // Get current navigation
    this._itlsNavigationService.onNavigationChanged
      .pipe(
        filter((value) => value !== null),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.navigation = this._itlsNavigationService.getCurrentNavigation();
      });

    NavigationItemService.openNewFormModal$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.openFormModal();
      });

    NavigationItemService.openAddQueue$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.openQueueModal();
      });

    NavigationItemService.openAddProject$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.openAddProjectModal();
      });

    if (
      !window.location.pathname.includes("auth/terms-of-service") &&
      this.authService.isLoggedIn()
    ) {
      this.initActions();
      this.initListeners();
    } else {
      this.authService
        .onLogin()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.initActions();
          this.initListeners();
        });
    }

    this.authService
      .onLogin()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.userEmail = UserInfoService.getUserEmail();
        this.userName = UserInfoService.getUserFullName();
      });
  }

  initActions(): void {
    this.notificationsService.connect({});
    this.notificationsFacade.loadCountOfUnseenNotifications({});
  }

  initListeners(): void {
    this.notificationsService.getMessagesForCurrentUser();
    CentrifugeService.onNewNotification
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newNotification) => {
        const { category } = newNotification;
        this.notificationsFacade.incrementCountOfUnseenSelectedCategoryNotifications(
          {
            category,
            numberOfNotifications: 1,
          }
        );
        this.notificationsFacade.addNotificationToSelectedCategoryNotifications(
          {
            category,
            data: newNotification,
          }
        );
        this.notificationsFacade.incrementCountOfUnseenNotifications({
          numberOfNewNotifications: 1,
        });
        this.showSuccessMessageForFinishedDocumentSetUpload(newNotification);
      });
  }

  toggleSidebarFolded(): void {
    this.mainDrawerService.closeSidebar();
  }

  onNavigateToClick(key: string) {
    if (key === "logout") {
      this.authService.logout();
    } else {
      this._router.navigateByUrl("/settings/profile");
    }
  }

  public openFormModal() {
    ProvisionFormsModalService.openNewFormModal$.next();
    const data = new ModalInputModel();
    this.provisionFormsService
      .openAddProvisionFormModal(data)
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse) {
          const { type } = modalResponse;
          if (type === ModalsResponseTypeEnum.CLOSE) {
            const extras = {
              state: {
                formName: modalResponse.data.name,
                description: modalResponse.data.description,
                flags: modalResponse.data.flags,
              },
            };
            this._router.routeReuseStrategy.shouldReuseRoute = () => {
              return false;
            };
            this._router.navigate(["/individual-form/new"], extras);
          }
        }
      });
  }

  public openQueueModal() {
    const data = new ModalInputModel();
    this.queueModalService
      .openAddQueueModal(data)
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse) {
          const { type } = modalResponse;
          if (
            type === ModalsResponseTypeEnum.CLOSE &&
            modalResponse.data?.uid
          ) {
            this._router.navigate([`queues/${modalResponse.data.uid}/`]);
          }
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onNotificationSelect(evt: any) {
    this.notificationMenuTrigger.closeMenu();
  }

  private openAddProjectModal() {
    const dialogRef = this.dialog.open(AddProjectComponent, {
      width: "275px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((uid) => {
      if (uid) {
        this._router.routeReuseStrategy.shouldReuseRoute = () => {
          return false;
        };
        this._router.navigate([`projects/${uid}/kanban`]);
      }
    });
  }

  openUploadModal() {
    this.uploadModalService.openUploadModal().subscribe(() => {
      //
    });
  }

  private showSuccessMessageForFinishedDocumentSetUpload(newNotification: any) {
    if (newNotification.type === NotificationTypesEnum.ABSTRACTION_FINISHED) {
      const abstractTitleWithoutExtension = this.removeExtension(
        newNotification.abstractTitle
      );
      const abstractLink = `/abstract-review/${newNotification.abstractUid}`;
      const abstractLinkElement = `<a href="${abstractLink}" target="_blank" class="blue-link">${abstractTitleWithoutExtension}</a>`;
      this.messageService.success(
        `Your uploaded record ${abstractLinkElement} has been processed and is ready for review.`,
        {
          Style: "flip",
          Duration: 15000,
        }
      );
    } else if (
      newNotification.type ===
      NotificationTypesEnum.MASS_UPLOAD_ABSTRACTION_FINISHED
    ) {
      const folderName = newNotification.metadata.folderName;
      const folderLink = `/drive/folders/${newNotification.metadata.folderUid}`;
      const folderLinkElement = `<a href="${folderLink}" target="_blank" class="blue-link">${folderName}</a>`;
      this.messageService.success(
        `Processing for the folder ${folderLinkElement} has finished.`,
        {
          Style: "flip",
          Duration: 15000,
        }
      );
    }
  }

  //TODO(reza) this is not the responsibility of this file
  private removeExtension(name: string) {
    if (name) {
      const lastDotIdx = name.lastIndexOf(".");
      if (lastDotIdx !== -1) {
        name = name.substr(0, lastDotIdx);
      }
    }
    return name;
  }
}
