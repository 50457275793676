/* tslint:disable */
/* eslint-disable */
import { PaginationDataModel } from "./pagination-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiMultiEntityContainerResponseEntityTypeModel")
export class OApiMultiEntityContainerResponseEntityTypeModel {
  @JsonProperty("items", Any, true)
  items?: Array<
    | "FINAL_ABSTRACT"
    | "AUTO_ABSTRACT"
    | "PDF_DOCUMENT_ABSTRACT"
    | "PROVISION_FORM"
    | "PROVISION_LIBRARY"
    | "PROVISION_INFO"
    | "PROJECT"
    | "DASHBOARD"
    | "DIRECTORY"
    | "STACK"
    | "DOC_SET"
    | "QUEUE"
  > = [];

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
