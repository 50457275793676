/* tslint:disable */
/* eslint-disable */
import { ProvisionIndexDependencyModel } from "./provision-index-dependency-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionIndexDependenciesModel")
export class ProvisionIndexDependenciesModel {
  @JsonProperty("provisionModelKeyToDependency", Any, true)
  provisionModelKeyToDependency?: {
    [key: string]: ProvisionIndexDependencyModel;
  } = {};
}
