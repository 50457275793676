/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiDeleteBatchRequestBodyModel } from "../models/o-api-delete-batch-request-body-model";
import { OApiReqCloneQueueDtoModel } from "../models/o-api-req-clone-queue-dto-model";
import { OApiReqCreateQueueDtoModel } from "../models/o-api-req-create-queue-dto-model";
import { OApiReqEditQueueDtoModel } from "../models/o-api-req-edit-queue-dto-model";
import { OApiReqIngestBatchDocSetsIntoQueueModel } from "../models/o-api-req-ingest-batch-doc-sets-into-queue-model";
import { OApiRespIngestBatchDocSetsIntoQueueModel } from "../models/o-api-resp-ingest-batch-doc-sets-into-queue-model";
import { OApiRespMultiQueueDtoModel } from "../models/o-api-resp-multi-queue-dto-model";
import { OApiRespMultiSimpleQueueDtoModel } from "../models/o-api-resp-multi-simple-queue-dto-model";
import { OApiRespNextPrevQueueRecordDtoModel } from "../models/o-api-resp-next-prev-queue-record-dto-model";
import { OApiRespQueueDetailsDtoModel } from "../models/o-api-resp-queue-details-dto-model";
import { OApiSingleEntityNoDataResponseModel } from "../models/o-api-single-entity-no-data-response-model";
import { RequestModel } from "../models/request-model";

@Injectable({
  providedIn: "root",
})
export class QueueService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation cloneQueue
   */
  static readonly CloneQueuePath = "/api/v1/queues/clone";

  /**
   * Clone a queue.
   *
   * If it succeeds, it returns status 200 and a view of the cloned queue. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cloneQueue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloneQueue$Response(params: {
    /**
     * The data provided by the user, for cloning a queue.
     */
    body: OApiReqCloneQueueDtoModel;
  }): Observable<StrictHttpResponse<OApiRespQueueDetailsDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QueueService.CloneQueuePath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespQueueDetailsDtoModel>;
        })
      );
  }

  /**
   * Clone a queue.
   *
   * If it succeeds, it returns status 200 and a view of the cloned queue. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cloneQueue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloneQueue(params: {
    /**
     * The data provided by the user, for cloning a queue.
     */
    body: OApiReqCloneQueueDtoModel;
  }): Observable<OApiRespQueueDetailsDtoModel> {
    return this.cloneQueue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespQueueDetailsDtoModel>) =>
          r.body as OApiRespQueueDetailsDtoModel
      )
    );
  }

  /**
   * Path part for operation getAll1
   */
  static readonly GetAll1Path = "/api/v1/queues";

  /**
   * Get list of queues.
   *
   * If it succeeds, it returns status 200 and list of the queue. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll1$Response(params: {
    /**
     * The user relation of the queue.
     */
    category: "OWNED" | "SHARED";
  }): Observable<StrictHttpResponse<OApiRespMultiQueueDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QueueService.GetAll1Path,
      "get"
    );
    if (params) {
      rb.query("category", params.category, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiQueueDtoModel>;
        })
      );
  }

  /**
   * Get list of queues.
   *
   * If it succeeds, it returns status 200 and list of the queue. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll1(params: {
    /**
     * The user relation of the queue.
     */
    category: "OWNED" | "SHARED";
  }): Observable<OApiRespMultiQueueDtoModel> {
    return this.getAll1$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiQueueDtoModel>) =>
          r.body as OApiRespMultiQueueDtoModel
      )
    );
  }

  /**
   * Path part for operation create1
   */
  static readonly Create1Path = "/api/v1/queues";

  /**
   * Create a queue.
   *
   * If it succeeds, it returns status 200 and a view of the created queue. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1$Response(params: {
    /**
     * The data provided by the user, for creating a queue.
     */
    body: OApiReqCreateQueueDtoModel;
  }): Observable<StrictHttpResponse<OApiRespQueueDetailsDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QueueService.Create1Path,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespQueueDetailsDtoModel>;
        })
      );
  }

  /**
   * Create a queue.
   *
   * If it succeeds, it returns status 200 and a view of the created queue. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1(params: {
    /**
     * The data provided by the user, for creating a queue.
     */
    body: OApiReqCreateQueueDtoModel;
  }): Observable<OApiRespQueueDetailsDtoModel> {
    return this.create1$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespQueueDetailsDtoModel>) =>
          r.body as OApiRespQueueDetailsDtoModel
      )
    );
  }

  /**
   * Path part for operation deleteBatch
   */
  static readonly DeleteBatchPath = "/api/v1/deleteBatch/queues";

  /**
   * Delete list of queues.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteBatch$Response(params: {
    body: OApiDeleteBatchRequestBodyModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QueueService.DeleteBatchPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Delete list of queues.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteBatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteBatch(params: {
    body: OApiDeleteBatchRequestBodyModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.deleteBatch$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation getOne1
   */
  static readonly GetOne1Path = "/api/v1/queues/{queueUid}";

  /**
   * Get a queue details.
   *
   * If it succeeds, it returns status 200 and details information about the queue. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOne1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOne1$Response(params: {
    /**
     * The unique id of the queue.
     */
    queueUid: string;
  }): Observable<StrictHttpResponse<OApiRespQueueDetailsDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QueueService.GetOne1Path,
      "get"
    );
    if (params) {
      rb.path("queueUid", params.queueUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespQueueDetailsDtoModel>;
        })
      );
  }

  /**
   * Get a queue details.
   *
   * If it succeeds, it returns status 200 and details information about the queue. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOne1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOne1(params: {
    /**
     * The unique id of the queue.
     */
    queueUid: string;
  }): Observable<OApiRespQueueDetailsDtoModel> {
    return this.getOne1$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespQueueDetailsDtoModel>) =>
          r.body as OApiRespQueueDetailsDtoModel
      )
    );
  }

  /**
   * Path part for operation edit1
   */
  static readonly Edit1Path = "/api/v1/queues/{queueUid}";

  /**
   * Edit a queue.
   *
   * If it succeeds, it returns status 200 and a view of the edited queue.If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `edit1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  edit1$Response(params: {
    /**
     * The unique id of the queue.
     */
    queueUid: string;

    /**
     * The data provided by the user, for editing a queue.
     */
    body: OApiReqEditQueueDtoModel;
  }): Observable<StrictHttpResponse<OApiRespQueueDetailsDtoModel>> {
    const rb = new RequestBuilder(this.rootUrl, QueueService.Edit1Path, "put");
    if (params) {
      rb.path("queueUid", params.queueUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespQueueDetailsDtoModel>;
        })
      );
  }

  /**
   * Edit a queue.
   *
   * If it succeeds, it returns status 200 and a view of the edited queue.If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `edit1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  edit1(params: {
    /**
     * The unique id of the queue.
     */
    queueUid: string;

    /**
     * The data provided by the user, for editing a queue.
     */
    body: OApiReqEditQueueDtoModel;
  }): Observable<OApiRespQueueDetailsDtoModel> {
    return this.edit1$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespQueueDetailsDtoModel>) =>
          r.body as OApiRespQueueDetailsDtoModel
      )
    );
  }

  /**
   * Path part for operation deleteByUid2
   */
  static readonly DeleteByUid2Path = "/api/v1/queues/{queueUid}";

  /**
   * Delete a queue.
   *
   * If it succeeds, it returns status 200 and information about the queue. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteByUid2()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByUid2$Response(params: {
    /**
     * The unique id of the queue.
     */
    queueUid: string;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QueueService.DeleteByUid2Path,
      "delete"
    );
    if (params) {
      rb.path("queueUid", params.queueUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Delete a queue.
   *
   * If it succeeds, it returns status 200 and information about the queue. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteByUid2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByUid2(params: {
    /**
     * The unique id of the queue.
     */
    queueUid: string;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.deleteByUid2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation pause
   */
  static readonly PausePath = "/api/v1/queues/{queueUid}/pause";

  /**
   * Pause a queue.
   *
   * If it succeeds, it pause the queue returns status 200 with details information about the queue. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pause()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  pause$Response(params: {
    /**
     * The unique id of the queue.
     */
    queueUid: string;
    body?: RequestModel;
  }): Observable<StrictHttpResponse<OApiRespQueueDetailsDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QueueService.PausePath,
      "patch"
    );
    if (params) {
      rb.path("queueUid", params.queueUid, {});
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespQueueDetailsDtoModel>;
        })
      );
  }

  /**
   * Pause a queue.
   *
   * If it succeeds, it pause the queue returns status 200 with details information about the queue. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pause$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  pause(params: {
    /**
     * The unique id of the queue.
     */
    queueUid: string;
    body?: RequestModel;
  }): Observable<OApiRespQueueDetailsDtoModel> {
    return this.pause$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespQueueDetailsDtoModel>) =>
          r.body as OApiRespQueueDetailsDtoModel
      )
    );
  }

  /**
   * Path part for operation unPause
   */
  static readonly UnPausePath = "/api/v1/queues/{queueUid}/un-pause";

  /**
   * Unpause a queue.
   *
   * If it succeeds, it unpause the queue returns status 200 with details information about the queue. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unPause()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  unPause$Response(params: {
    /**
     * The unique id of the queue.
     */
    queueUid: string;
    body?: RequestModel;
  }): Observable<StrictHttpResponse<OApiRespQueueDetailsDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QueueService.UnPausePath,
      "patch"
    );
    if (params) {
      rb.path("queueUid", params.queueUid, {});
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespQueueDetailsDtoModel>;
        })
      );
  }

  /**
   * Unpause a queue.
   *
   * If it succeeds, it unpause the queue returns status 200 with details information about the queue. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unPause$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  unPause(params: {
    /**
     * The unique id of the queue.
     */
    queueUid: string;
    body?: RequestModel;
  }): Observable<OApiRespQueueDetailsDtoModel> {
    return this.unPause$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespQueueDetailsDtoModel>) =>
          r.body as OApiRespQueueDetailsDtoModel
      )
    );
  }

  /**
   * Path part for operation getUsable
   */
  static readonly GetUsablePath = "/api/v1/usable/queues";

  /**
   * Get list of usable queues.
   *
   * If it succeeds, it returns status 200 and list of the queue. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsable()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsable$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespMultiSimpleQueueDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      QueueService.GetUsablePath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiSimpleQueueDtoModel>;
        })
      );
  }

  /**
   * Get list of usable queues.
   *
   * If it succeeds, it returns status 200 and list of the queue. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsable(params?: {}): Observable<OApiRespMultiSimpleQueueDtoModel> {
    return this.getUsable$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiSimpleQueueDtoModel>) =>
          r.body as OApiRespMultiSimpleQueueDtoModel
      )
    );
  }

  /**
   * Path part for operation exportQueueRecords
   */
  static readonly ExportQueueRecordsPath = "/api/v1/queues/{queueUid}/export";

  /**
   * Export the details of this queue's records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportQueueRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportQueueRecords$Response(params: {
    queueUid: string;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QueueService.ExportQueueRecordsPath,
      "get"
    );
    if (params) {
      rb.path("queueUid", params.queueUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Export the details of this queue's records.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportQueueRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportQueueRecords(params: {
    queueUid: string;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.exportQueueRecords$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation getNextAndPreviousRecordInfo
   */
  static readonly GetNextAndPreviousRecordInfoPath =
    "/api/v1/queues/records/{recordUid}/records-in-queue-stage";

  /**
   * Get the next and previous record of the same stage in the queue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNextAndPreviousRecordInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNextAndPreviousRecordInfo$Response(params: {
    recordUid: string;
    queueStage:
      | "PROCESSING"
      | "READY"
      | "IN_REVIEW"
      | "NEED_QA_REVIEW"
      | "IN_QA_REVIEW"
      | "COMPLETED"
      | "FAILED";
  }): Observable<StrictHttpResponse<OApiRespNextPrevQueueRecordDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QueueService.GetNextAndPreviousRecordInfoPath,
      "get"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.query("queueStage", params.queueStage, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespNextPrevQueueRecordDtoModel>;
        })
      );
  }

  /**
   * Get the next and previous record of the same stage in the queue.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNextAndPreviousRecordInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNextAndPreviousRecordInfo(params: {
    recordUid: string;
    queueStage:
      | "PROCESSING"
      | "READY"
      | "IN_REVIEW"
      | "NEED_QA_REVIEW"
      | "IN_QA_REVIEW"
      | "COMPLETED"
      | "FAILED";
  }): Observable<OApiRespNextPrevQueueRecordDtoModel> {
    return this.getNextAndPreviousRecordInfo$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespNextPrevQueueRecordDtoModel>) =>
          r.body as OApiRespNextPrevQueueRecordDtoModel
      )
    );
  }

  /**
   * Path part for operation ingestBatchDocSetsIntoQueue
   */
  static readonly IngestBatchDocSetsIntoQueuePath =
    "/api/v1/queues/{queueUid}/doc-sets/ingest-batch";

  /**
   * Ingest one or more docSet into the queue for processing and creating record.
   *
   * If it succeeds, it returns status 200 and details information about the created records.If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ingestBatchDocSetsIntoQueue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ingestBatchDocSetsIntoQueue$Response(params: {
    /**
     * The unique id of the Queue.
     */
    queueUid: string;

    /**
     * The data provided by the user, for ingesting one or more docSets.
     */
    body: OApiReqIngestBatchDocSetsIntoQueueModel;
  }): Observable<StrictHttpResponse<OApiRespIngestBatchDocSetsIntoQueueModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QueueService.IngestBatchDocSetsIntoQueuePath,
      "post"
    );
    if (params) {
      rb.path("queueUid", params.queueUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespIngestBatchDocSetsIntoQueueModel>;
        })
      );
  }

  /**
   * Ingest one or more docSet into the queue for processing and creating record.
   *
   * If it succeeds, it returns status 200 and details information about the created records.If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ingestBatchDocSetsIntoQueue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ingestBatchDocSetsIntoQueue(params: {
    /**
     * The unique id of the Queue.
     */
    queueUid: string;

    /**
     * The data provided by the user, for ingesting one or more docSets.
     */
    body: OApiReqIngestBatchDocSetsIntoQueueModel;
  }): Observable<OApiRespIngestBatchDocSetsIntoQueueModel> {
    return this.ingestBatchDocSetsIntoQueue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespIngestBatchDocSetsIntoQueueModel>) =>
          r.body as OApiRespIngestBatchDocSetsIntoQueueModel
      )
    );
  }
}
