/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiReqErrorsDtoModel } from "../models/o-api-req-errors-dto-model";
import { OApiRespFullNameUserViewModel } from "../models/o-api-resp-full-name-user-view-model";
import { OApiRespHeaderUserViewModel } from "../models/o-api-resp-header-user-view-model";
import { OApiRespLoggedInUserViewModel } from "../models/o-api-resp-logged-in-user-view-model";
import { OApiRespMetricsModel } from "../models/o-api-resp-metrics-model";
import { OApiRespMinimalUserViewModel } from "../models/o-api-resp-minimal-user-view-model";
import { OApiRespProfileUserViewModel } from "../models/o-api-resp-profile-user-view-model";
import { OApiSingleEntityNoDataResponseModel } from "../models/o-api-single-entity-no-data-response-model";
import { RequestModel } from "../models/request-model";

@Injectable({
  providedIn: "root",
})
export class UtilityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation login
   */
  static readonly LoginPath = "/api/v1/login";

  /**
   * Login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  login$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespMinimalUserViewModel
      | OApiRespFullNameUserViewModel
      | OApiRespHeaderUserViewModel
      | OApiRespProfileUserViewModel
      | OApiRespLoggedInUserViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UtilityService.LoginPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
          >;
        })
      );
  }

  /**
   * Login.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  login(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespMinimalUserViewModel
    | OApiRespFullNameUserViewModel
    | OApiRespHeaderUserViewModel
    | OApiRespProfileUserViewModel
    | OApiRespLoggedInUserViewModel
  > {
    return this.login$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
          >
        ) =>
          r.body as
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
      )
    );
  }

  /**
   * Path part for operation userAcceptTermsOfService
   */
  static readonly UserAcceptTermsOfServicePath =
    "/api/v1/users/{userUid}/termsOfService";

  /**
   * Accept terms of service.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAcceptTermsOfService()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  userAcceptTermsOfService$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespMinimalUserViewModel
      | OApiRespFullNameUserViewModel
      | OApiRespHeaderUserViewModel
      | OApiRespProfileUserViewModel
      | OApiRespLoggedInUserViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UtilityService.UserAcceptTermsOfServicePath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
          >;
        })
      );
  }

  /**
   * Accept terms of service.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAcceptTermsOfService$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  userAcceptTermsOfService(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespMinimalUserViewModel
    | OApiRespFullNameUserViewModel
    | OApiRespHeaderUserViewModel
    | OApiRespProfileUserViewModel
    | OApiRespLoggedInUserViewModel
  > {
    return this.userAcceptTermsOfService$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
          >
        ) =>
          r.body as
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
      )
    );
  }

  /**
   * Path part for operation logout
   */
  static readonly LogoutPath = "/api/v1/logout";

  /**
   * Logout.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logout()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  logout$Response(params?: {
    body?: RequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UtilityService.LogoutPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Logout.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logout$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  logout(params?: {
    body?: RequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.logout$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation checkResetPassword
   */
  static readonly CheckResetPasswordPath = "/api/v1/resetPassword/{resetId}";

  /**
   * Check reset password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkResetPassword()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkResetPassword$Response(params?: {}): Observable<
    StrictHttpResponse<OApiSingleEntityNoDataResponseModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UtilityService.CheckResetPasswordPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Check reset password.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkResetPassword$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkResetPassword(params?: {}): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.checkResetPassword$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation resetPassword
   */
  static readonly ResetPasswordPath = "/api/v1/resetPassword/{resetId}";

  /**
   * Reset password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPassword()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  resetPassword$Response(params?: {
    body?: RequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UtilityService.ResetPasswordPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Reset password.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetPassword$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  resetPassword(params?: {
    body?: RequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.resetPassword$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation forgotPassword
   */
  static readonly ForgotPasswordPath = "/api/v1/forgotPassword";

  /**
   * Forgot password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgotPassword()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  forgotPassword$Response(params?: {
    body?: RequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UtilityService.ForgotPasswordPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Forgot password.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `forgotPassword$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  forgotPassword(params?: {
    body?: RequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.forgotPassword$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation getUserMetrics
   */
  static readonly GetUserMetricsPath = "/api/v1/metrics/users/{userUid}";

  /**
   * Get user metrics.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserMetrics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserMetrics$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespMetricsModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UtilityService.GetUserMetricsPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMetricsModel>;
        })
      );
  }

  /**
   * Get user metrics.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserMetrics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserMetrics(params?: {}): Observable<OApiRespMetricsModel> {
    return this.getUserMetrics$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMetricsModel>) =>
          r.body as OApiRespMetricsModel
      )
    );
  }

  /**
   * Path part for operation logFrontendErrors
   */
  static readonly LogFrontendErrorsPath = "/api/v1/log-frontend-errors";

  /**
   * Log Frontend Errors.
   *
   * Log the errors reported by the frontend.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logFrontendErrors()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  logFrontendErrors$Response(params: {
    /**
     * The error data to be sent by the frontend.
     */
    body: OApiReqErrorsDtoModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UtilityService.LogFrontendErrorsPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Log Frontend Errors.
   *
   * Log the errors reported by the frontend.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logFrontendErrors$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  logFrontendErrors(params: {
    /**
     * The error data to be sent by the frontend.
     */
    body: OApiReqErrorsDtoModel;
  }): Observable<void> {
    return this.logFrontendErrors$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
