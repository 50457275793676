import { ProvisionFormsModalService } from "@@intelease/web/forms/src/lib/services";
import { QueueModalService } from "@@intelease/web/queues/src/lib/services/queue.modal.service";
import { UploadModalService } from "@@intelease/web/ui/src/lib/itls-new-upload";
import { ItlsTableService } from "@@intelease/web/ui/src/lib/itls-table/service/itls-table.service";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NavigationItemService } from "../../constants/navigation-item.service";
import { ModalsResponseTypeEnum } from "../../enums";
import { ModalInputModel } from "../../models";
import { FeaturesToggleService, UserInfoService } from "../../services";
import { LeftMenuAction, LeftMenuService } from "./left-menu.service";
import { QueueFeatureService } from "../../utils";
import { AddProjectComponent } from "@@intelease/web/projects/src/lib/modals/add-project";
import { AppAuthorities } from "../../constants";
import {
  CentrifugeService,
  NotificationsService,
} from "@@intelease/app-services/notifications/src";
import { NotificationTypesEnum } from "@@intelease/app-models/notifications/src";
import { MessageService } from "../../components/message/message.service";
import { LocalStorageService } from "@@intelease/app-services/common/src";
import {
  EditLbxRolesDtoModel,
  GoogleDriveInfoViewModel,
} from "@@intelease/api-models/adex-api-model-src";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "left-menu",
  templateUrl: "./left-menu.component.html",
  styleUrls: ["./left-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftMenuComponent implements OnInit {
  public expanded = false;
  dashboards$: Observable<boolean> = this.featuresToggleService.dashboard$;
  isQueueEnabled = this.queueFeatureService.isFeatureEnabled();
  AppAuthorities: typeof AppAuthorities = AppAuthorities;

  lbxRoles: EditLbxRolesDtoModel["lbxRoles"];

  googleDriveInfoView?: GoogleDriveInfoViewModel;

  private actions = {
    [LeftMenuAction.COLLAPSE]: () => (this.expanded = false),
    [LeftMenuAction.EXPAND]: () => (this.expanded = true),
    [LeftMenuAction.TOGGLE]: () => (this.expanded = !this.expanded),
  };

  constructor(
    private readonly uploadModalService: UploadModalService,
    private readonly featuresToggleService: FeaturesToggleService,
    private provisionFormsService: ProvisionFormsModalService,
    private queueModalService: QueueModalService,
    private _router: Router,
    private dialog: MatDialog,
    private readonly queueFeatureService: QueueFeatureService,
    private readonly leftMenuService: LeftMenuService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    private messageService: MessageService
  ) {
    this.googleDriveInfoView = LocalStorageService.getGoogleDriveViewInfo();
    this.leftMenuService.leftMenuAction$
      .pipe(takeUntilDestroyed())
      .subscribe((action) => {
        this.actions[action]();
        this.changeDetectorRef.markForCheck();
      });
    NavigationItemService.openNewFormModal$
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.openFormModal();
      });
    NavigationItemService.openAddQueue$
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.openQueueModal();
      });
    NavigationItemService.openAddProject$
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.openAddProjectModal();
      });
  }

  ngOnInit(): void {
    this.isQueueEnabled = this.queueFeatureService.isFeatureEnabled();
    this.lbxRoles = UserInfoService.getLbxRoles() || [];
    this.initNotification();
  }

  canUploadDocSet() {
    return (
      !UserInfoService.isLbxAccountUser() ||
      this.lbxRoles.includes("FORMS_ADMIN")
    );
  }

  private initNotification() {
    this.notificationsService.connect({});
    this.notificationsService.getMessagesForCurrentUser();

    CentrifugeService.onNewNotification.subscribe((newNotification) => {
      this.showSuccessMessageForFinishedDocumentSetUpload(newNotification);
    });
  }

  public openUploadModal(): void {
    if (this.isQueueEnabled) {
      this.uploadModalService.openUploadDocSetModal().subscribe();
    } else {
      this.uploadModalService.openUploadModal().subscribe();
    }
  }

  public toggleMenu() {
    this.expanded = !this.expanded;
    if (document.querySelector(".itls-table"))
      ItlsTableService.setTableWidth.next();
  }

  public openFormModal() {
    ProvisionFormsModalService.openNewFormModal$.next();
    const data = new ModalInputModel();
    this.provisionFormsService
      .openAddProvisionFormModal(data)
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse) {
          const { type } = modalResponse;
          if (type === ModalsResponseTypeEnum.CLOSE) {
            const extras = {
              state: {
                formName: modalResponse.data.name,
                description: modalResponse.data.description,
                flags: modalResponse.data.flags,
              },
            };
            this._router.routeReuseStrategy.shouldReuseRoute = () => {
              return false;
            };
            this._router.navigate(["/individual-form/new"], extras);
          }
        }
      });
  }

  public openQueueModal() {
    const data = new ModalInputModel();
    this.queueModalService
      .openAddQueueModal(data)
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse) {
          const { type } = modalResponse;
          if (
            type === ModalsResponseTypeEnum.CLOSE &&
            modalResponse.data?.uid
          ) {
            this._router.navigate([`queues/${modalResponse.data.uid}/`]);
          }
        }
      });
  }

  private openAddProjectModal() {
    const dialogRef = this.dialog.open(AddProjectComponent, {
      width: "275px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((uid) => {
      if (uid) {
        this._router.routeReuseStrategy.shouldReuseRoute = () => {
          return false;
        };
        this._router.navigate([`projects/${uid}/kanban`]);
      }
    });
  }

  private showSuccessMessageForFinishedDocumentSetUpload(newNotification: any) {
    if (newNotification.type === NotificationTypesEnum.ABSTRACTION_FINISHED) {
      const abstractTitleWithoutExtension = this.removeExtension(
        newNotification.abstractTitle
      );
      const abstractLink = `/abstract-review/${newNotification.abstractUid}`;
      const abstractLinkElement = `<a href="${abstractLink}" target="_blank" class="blue-link">${abstractTitleWithoutExtension}</a>`;
      this.messageService.success(
        `Your uploaded record ${abstractLinkElement} has been processed and is ready for review.`,
        {
          Style: "flip",
          Duration: 15000,
        }
      );
    } else if (
      newNotification.type ===
      NotificationTypesEnum.MASS_UPLOAD_ABSTRACTION_FINISHED
    ) {
      const folderName = newNotification.metadata.folderName;
      const folderLink = `/drive/folders/${newNotification.metadata.folderUid}`;
      const folderLinkElement = `<a href="${folderLink}" target="_blank" class="blue-link">${folderName}</a>`;
      this.messageService.success(
        `Processing for the folder ${folderLinkElement} has finished.`,
        {
          Style: "flip",
          Duration: 15000,
        }
      );
    }
  }

  private removeExtension(name: string) {
    if (name) {
      const lastDotIdx = name.lastIndexOf(".");
      if (lastDotIdx !== -1) {
        name = name.substr(0, lastDotIdx);
      }
    }
    return name;
  }
}
