import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NavigationItemService {
  static openNewFormModal$ = new Subject<void>();
  static openAddProject$ = new Subject<void>();
  static openAddQueue$ = new Subject<void>();
  static openAddFolder$ = new Subject<void>();
}
