/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RecordArtifactTypesModel")
export class RecordArtifactTypesModel {
  @JsonProperty("recordArtifactTypes", Any, true)
  recordArtifactTypes?: Array<
    | "HORIZ_OCR"
    | "ORIG_OCR"
    | "RAW_OCR"
    | "PNG_PAGES"
    | "UNLABELED_VALUES"
    | "HANDWRITING"
    | "JSON_RECORD"
    | "ANNOTATED_DOCS"
    | "SUMMARY"
    | "IMAGES"
    | "TABLES"
    | "BOLD_WORDS"
    | "RAW_TXT_BLOCKS"
    | "TXT_BLOCKS"
  > = [];
}
