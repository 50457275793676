/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EntityIdDtoModel")
export class EntityIdDtoModel {
  @JsonProperty("type", Any, true)
  type?:
    | "FINAL_ABSTRACT"
    | "AUTO_ABSTRACT"
    | "PDF_DOCUMENT_ABSTRACT"
    | "PROVISION_FORM"
    | "PROVISION_LIBRARY"
    | "PROVISION_INFO"
    | "PROJECT"
    | "DASHBOARD"
    | "DIRECTORY"
    | "STACK"
    | "DOC_SET"
    | "QUEUE" = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
