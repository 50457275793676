<div class="py-3 navigation-container">
  <div class="nav-icon-outer">
    <a
      routerLink="/dashboards"
      [class.active]="isDashboardActive()"
      (click)="onMenuItemClick('/dashboards')"
      class="nav-text"
      dataCy="dashboard-anchor"
    >
      <i class="fa fa-th-large nav-icon"></i>
      Dashboard
    </a>
  </div>
  <div class="nav-icon-outer">
    <a
      routerLink="/my-documents/documents"
      [class.active]="isAbstractsActive()"
      (click)="onMenuItemClick('/my-documents')"
      class="nav-text"
      dataCy="documents-anchor"
    >
      <i class="fa fa-folder nav-icon"></i>
      Documents
    </a>
  </div>
  <div class="nav-icon-outer">
    <a
      routerLink="/provision-forms/forms"
      [class.active]="isFormsActive()"
      (click)="onMenuItemClick('/provision-forms')"
      class="nav-text"
      dataCy="provision-forms-anchor"
    >
      <i class="fa fa-file nav-icon"></i>
      Forms
    </a>
  </div>
  <div class="nav-icon-outer">
    <a
      routerLink="/tasks/list"
      [class.active]="isTasksActive()"
      (click)="onMenuItemClick('/tasks')"
      class="nav-text"
      dataCy="tasks-anchor"
    >
      <i class="fa fa-tasks nav-icon"></i>
      Tasks
    </a>
  </div>
</div>
