import { ItlsNavigationItem } from "../interfaces";
import { NavigationItemService } from "@@intelease/web/intelease/constants/navigation-item.service";

export const NAVIGATION: ItlsNavigationItem[] = [
  {
    id: "FIRST",
    title: "Applications",
    type: "group",
    icon: "apps",
    classes: "pt-4",
    groupType: "line",
    children: [
      {
        id: "recordsGroup",
        title: "Records",
        translate: "Records",
        icon: "",
        type: "group",
        exactMatch: true,
        children: [
          {
            id: "records",
            title: "Records",
            translate: "Records",
            type: "collapsable",
            icon: "",
            exactMatch: true,
            children: [
              {
                id: "sharedDrive",
                title: "Shared with Me",
                translate: "Shared with Me",
                type: "item",
                exactMatch: true,
                url: "/drive/shared",
              },
              {
                id: "allDrive",
                title: "All Records",
                translate: "All Records",
                type: "item",
                exactMatch: true,
                url: "/drive/all",
              },
              {
                id: "deletedDrive",
                title: "Trash",
                translate: "Trash",
                type: "item",
                exactMatch: true,
                url: "/drive/deleted",
              },
            ],
          },
        ],
      },
      {
        id: "tasks",
        title: "Tasks",
        translate: "Tasks",
        type: "item",
        url: "/tasks/list",
      },
      {
        id: "myForms",
        title: "Forms",
        translate: "Forms",
        type: "item",
        exactMatch: true,
        url: "/provision-forms/forms",
        icon: "add",
        iconTooltip: "Create a New Provision Form",
        iconFunction: (event) => {
          event.stopPropagation();
          event.preventDefault();
          NavigationItemService.openNewFormModal$.next();
        },
      },
      {
        id: "projectsGroup",
        title: "Projects",
        translate: "Projects",
        type: "item",
        exactMatch: true,
        url: "/projects/list",
        icon: "add",
        iconTooltip: "Create a New Project",
        iconFunction: (event) => {
          event.stopPropagation();
          event.preventDefault();
          NavigationItemService.openAddProject$.next();
        },
      },
    ],
  },
];
export const QA_NAVIGATION: ItlsNavigationItem[] = [
  {
    id: "FIRST",
    title: "Applications",
    type: "group",
    icon: "apps",
    classes: "pt-4",
    groupType: "line",
    children: [
      {
        id: "documentSetGroup",
        title: "Records",
        translate: "Records",
        icon: "",
        type: "group",
        exactMatch: true,
        children: [
          {
            id: "records",
            title: "Records",
            translate: "Records",
            type: "collapsable",
            icon: "",
            exactMatch: true,
            children: [
              {
                id: "sharedDrive",
                title: "Shared with Me",
                translate: "Shared with Me",
                type: "item",
                exactMatch: true,
                url: "/drive/shared",
              },
              {
                id: "allDrive",
                title: "All Records",
                translate: "All Records",
                type: "item",
                exactMatch: true,
                url: "/drive/all",
              },
              {
                id: "allDocuments",
                title: "All Documents",
                translate: "All Documents",
                type: "item",
                exactMatch: true,
                url: "/drive/documents",
              },
              {
                id: "deletedDrive",
                title: "Trash",
                translate: "Trash",
                type: "item",
                exactMatch: true,
                url: "/drive/deleted",
              },
            ],
          },
        ],
      },
      {
        id: "tasks",
        title: "Tasks",
        translate: "Tasks",
        type: "item",
        url: "/tasks/list",
      },
      {
        id: "myForms",
        title: "Forms",
        translate: "Forms",
        type: "item",
        exactMatch: true,
        url: "/provision-forms/forms",
        icon: "add",
        iconTooltip: "Create a New Provision Form",
        iconFunction: (event) => {
          event.stopPropagation();
          event.preventDefault();
          NavigationItemService.openNewFormModal$.next();
        },
      },
      {
        id: "projectsGroup",
        title: "Projects",
        translate: "Projects",
        type: "item",
        exactMatch: true,
        url: "/projects/list",
        icon: "add",
        iconTooltip: "Create a New Project",
        iconFunction: (event) => {
          event.stopPropagation();
          event.preventDefault();
          NavigationItemService.openAddProject$.next();
        },
      },
      {
        id: "queues",
        title: "Queues",
        translate: "Queues",
        type: "item",
        exactMatch: true,
        url: "/queues/list",
        icon: "add",
        iconTooltip: "Create a New Queue",
        iconFunction: (event) => {
          event.stopPropagation();
          event.preventDefault();
          NavigationItemService.openAddQueue$.next();
        },
      },
    ],
  },
];
