/* tslint:disable */
/* eslint-disable */

/**
 * the term
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DoubleWithUnitModel")
export class DoubleWithUnitModel {
  /**
   * the specific unit for a numerical value
   */

  @JsonProperty("unit", Any, true)
  unit?:
    | "SQUARE_FEET"
    | "SQUARE_METERS"
    | "PING"
    | "TSUBO"
    | "ACRES"
    | "HECTARES"
    | "SQUARE_KILOMETERS"
    | "SQUARE_MILES"
    | "CENTIMETERS"
    | "INCHES"
    | "FEET"
    | "YARDS"
    | "METERS"
    | "KILOMETERS"
    | "MILES"
    | "PERCENT"
    | "MONEY"
    | "SECONDS"
    | "MINUTES"
    | "HOURS"
    | "DAYS"
    | "WEEKS"
    | "MONTHS"
    | "YEARS"
    | "METERS_PER_SECOND"
    | "METERS_PER_SECOND_PER_SECOND"
    | "GRAMS"
    | "CUBIC_CENTIMETERS"
    | "GRAMS_PER_CUBIC_CENTIMETER"
    | "VOLTS"
    | "AMPERES"
    | "PASCALS"
    | "INCHES_WATER"
    | "POUNDS_PER_SQUARE_INCH"
    | "ATMOSPHERES"
    | "KELVIN"
    | "CELSIUS"
    | "FAHRENHEIT"
    | "NONE" = undefined;

  @JsonProperty("value", Number, true)
  value?: number = undefined;
}
