import {
  ActorShareModel,
  NewShareEntityService,
} from "@@intelease/web/ui/src/lib/new-share-entity";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActorModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/actor.model";
import { RestClient } from "@@intelease/web/utils";
import { map } from "rxjs/operators";
import { EntityShareModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/entity-share.model";
import { EntityActorsPermissionsModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/entity-actors-permissions.model";

@Injectable({
  providedIn: "root",
})
export class ItlsShareDriveService implements NewShareEntityService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly BASE_URL = "/files";

  constructor(private restClient: RestClient) {}

  getPermissions(
    entities: EntityShareModel[]
  ): Observable<EntityActorsPermissionsModel[]> {
    return this.restClient
      .sendPostListRequest<EntityActorsPermissionsModel>(
        ItlsShareDriveService.API_VERSION_1,
        `${ItlsShareDriveService.BASE_URL}/permissions`,
        EntityActorsPermissionsModel,
        {
          data: {
            entities: entities.map((entity) => ({
              uid: entity.entityUid,
              type: entity.entityType,
            })),
          },
        },
        "full"
      )
      .pipe(map((resp) => resp.items));
  }

  removeShared(
    entities: EntityShareModel[],
    actorsToUnshareWith: ActorModel[]
  ): Observable<any> {
    return this.restClient.sendPostRequestNoView(
      ItlsShareDriveService.API_VERSION_1,
      `${ItlsShareDriveService.BASE_URL}/removeShared`,
      {
        data: {
          domains: actorsToUnshareWith.map((item) => ({
            uid: item.uid,
            type: item.type,
          })),
          entities: entities.map((entity) => ({
            uid: entity.entityUid,
            type: entity.entityType,
          })),
        },
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeSharedForAllDomains(entity: EntityShareModel): Observable<any> {
    throw new Error("not implemented");
  }

  share(entity: EntityShareModel, actors: ActorShareModel[]): Observable<any> {
    return this.restClient.sendPostRequestNoView(
      ItlsShareDriveService.API_VERSION_1,
      `${ItlsShareDriveService.BASE_URL}/share`,
      {
        data: {
          entities: [
            {
              uid: entity.entityUid,
              type: entity.entityType,
            },
          ],
          domains: actors,
        },
      }
    );
  }

  shareMulti(
    entities: EntityShareModel[],
    actors: ActorShareModel[]
  ): Observable<any> {
    return this.restClient.sendPostRequestNoView(
      ItlsShareDriveService.API_VERSION_1,
      `${ItlsShareDriveService.BASE_URL}/share`,
      {
        data: {
          entities: entities.map((entity) => ({
            uid: entity.entityUid,
            type: entity.entityType,
          })),
          domains: actors,
        },
      }
    );
  }
}
